import { Alert, Button, Col, Row, Select, Space, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { ConnectorResponse, RemoteMultiChargerTriggerMessageParams } from '../../../models/charger-remote'
import { SwtchError } from '../../../models/error'
import { RemoteMultiChargerTriggerMessage } from '../../../services/data-provider/charger-remote'
import { InputWrapper } from '../../../atom/chargers'
import { useChargerTranslation } from '../../../hooks/translation/useChargerTranslation'
import { useAppState } from 'state'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'

const { Option } = Select

interface props {
  chargers: RemoteMultiChargerTriggerMessageParams[]
}

type TriggerMessageType = {
  label: string
  value: string
}

const initialMessage = {
  label: 'Message',
  value: '',
}

export const RemoteTriggerMultipleChargersRedesign: React.FC<props> = ({ chargers }) => {
  document.querySelector('body')?.classList.add('redesignActive')
  const { isMockUpEnabled } = useAppState()
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [passed, setPassed] = useState(0)
  const [failed, setFailed] = useState(0)
  const [message, setMessage] = useState<ConnectorResponse[]>([])
  const [triggermessage, setTriggerMessage] = useState(initialMessage)
  const [error, setError] = useState<SwtchError[]>([])
  const [errorMessage, setErrorMessasge] = useState<ConnectorResponse[]>([])
  const {
    triggerText,
    messageTypePlaceHolder,
    sendTriggerMessageMultipleChargers,
    connectorText,
    bootNotificationText,
    diagnosticsStatusNotificationText,
    firmwareStatusNotificationText,
    heartBeatText,
    meterValueText,
    statusNotificationText,
    configurationTriggerText,
    sendTriggerMessageText,
  } = useChargerTranslation()

  const { somethingWentWrongText } = useGeneralTranslation()

  useEffect(() => {
    setLoading(false)
    setMessage([])
    setError([])
  }, [])

  const availableMessages: { label: string; value: string }[] = [
    {
      label: bootNotificationText,
      value: 'BootNotification',
    },
    {
      label: diagnosticsStatusNotificationText,
      value: 'DiagnosticsStatusNotification',
    },
    {
      label: firmwareStatusNotificationText,
      value: 'FirmwareStatusNotification',
    },
    {
      label: heartBeatText,
      value: 'Heartbeat',
    },
    {
      label: meterValueText,
      value: 'MeterValues',
    },
    {
      label: statusNotificationText,
      value: 'StatusNotification',
    },
  ]

  const handleTriggerMessage = () => {
    setLoading(true)
    setError([])
    setMessage([])
    setPassed(0)
    setFailed(0)
    RemoteMultiChargerTriggerMessage(isMockUpEnabled, {
      charger_params: [...chargers],
      message: triggermessage.value,
    })
      .then((resp) => {
        setPassed(resp.passed)
        setFailed(resp.failed)
        let p: ConnectorResponse[] = []
        let f: ConnectorResponse[] = []
        resp.payload.forEach((r: ConnectorResponse) => {
          r.success === true ? p.push(r) : f.push(r)
        })
        setMessage(p)
        setErrorMessasge(f)
      })
      .catch((err: SwtchError) => setError((error) => [...error, err]))
      .finally(() => setLoading(false))
  }

  const handleSelectTriggerMessage = ({ label, value }: TriggerMessageType) => {
    setTriggerMessage({ label, value })
    setDisabled(false)
  }

  const renderMessages = () => {
    return (
      <>
        {passed >= 1 &&
          message.map(
            (res, index) =>
              Array.isArray(res.message) && (
                <Alert
                  key={index}
                  message={`${res.serialNumber} - ${connectorText} ${res.connectorId}`}
                  description={res.message.length === 3 ? res.message?.[2]?.status : res.message?.[0]?.status}
                  type="info"
                  style={{ marginBottom: '10px' }}
                />
              ),
          )}
        {failed >= 1 &&
          errorMessage.map((err, index) => {
            const errMssg: any = err.message
            return (
              <>
                {
                  <Alert
                    key={index}
                    message={`${err.serialNumber} - ${connectorText} ${err.connectorId}`}
                    description={
                      errMssg['error']
                        ? errMssg['error']
                        : err.message instanceof Array
                        ? err.message.length === 3
                          ? err.message?.[2]?.status
                          : err.message?.[0]?.status
                        : somethingWentWrongText
                    }
                    type="error"
                  />
                }
              </>
            )
          })}
        {failed >= 1 &&
          error.map((err, index) => <Alert key={index} message={err.name} description={err.message} type="error" />)}
      </>
    )
  }

  return (
    <Row>
      <InputWrapper>
        <Col span={24}>
          <p>{configurationTriggerText}</p>
          <Space>
            <Select
              showSearch
              labelInValue
              dropdownMatchSelectWidth={false}
              placeholder={messageTypePlaceHolder}
              optionFilterProp="children"
              onChange={handleSelectTriggerMessage}
            >
              {availableMessages.map((opt) => {
                return (
                  <Option key={opt.value} value={opt.value}>
                    {opt.label}
                  </Option>
                )
              })}
            </Select>
            <Button type="primary" onClick={() => handleTriggerMessage()} loading={loading} disabled={disabled}>
              {message ? `${triggerText} ` : `${sendTriggerMessageText}`}
            </Button>
          </Space>
        </Col>
        <Col span={24} style={{ paddingTop: '10px' }}>
          {loading && (
            <Alert
              message={
                <div>
                  <Spin style={{ marginRight: '10px' }} />
                  {sendTriggerMessageMultipleChargers.replace('123', triggermessage.label)}
                </div>
              }
            />
          )}
          {!loading && renderMessages()}
        </Col>
      </InputWrapper>
    </Row>
  )
}
