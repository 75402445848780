import { Divider, Form, FormInstance, Switch } from 'antd'

import { useListingAccessTranslation } from '../../hooks/translation/useListingAccessTranslation'

interface props {
  form: FormInstance<any>
}

const BaseListingAccessVisibility: React.FC<props> = ({ form }) => {
  const { mapAndAccess, hiddenMapText, carRequiredText } = useListingAccessTranslation()
  return (
    <>
      <Divider>{mapAndAccess}</Divider>
      <Form.Item label={hiddenMapText} name="hidden" valuePropName="checked">
        <Switch style={{ float: 'left' }} />
      </Form.Item>
      <Form.Item label={carRequiredText} name="carDetailRequired" valuePropName="checked">
        <Switch style={{ float: 'left' }} />
      </Form.Item>
    </>
  )
}

export const ListingAccessVisibility = BaseListingAccessVisibility
