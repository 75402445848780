import { formatCentsPrice, formatPrice } from 'helpers/number-format'
import { isItHourOrkWh } from 'helpers/price/priceHelper'
import { NewListing } from 'models/listing'
import { FreeListing } from 'features/listings/prices/FreeListing'
import { Typography } from 'antd'

interface TimeOfDayPriceProps {
  listing: NewListing
  flatFee: number
  isLargeScreen: string
  isSmallScreen: string
  showTimeOfDayModal: (listing: NewListing) => void
  timeOfDayText: string
  feeText: string
}

export const TimeOfDayPrice: React.FC<TimeOfDayPriceProps> = ({
  listing,
  flatFee,
  isLargeScreen,
  isSmallScreen,
  showTimeOfDayModal,
  timeOfDayText,
  feeText,
}) => {
  const timeOfDayPriceWeekday = listing.price.timeOfDayPriceWeekday
  const timeOfDayPriceWeekend = listing.price.timeOfDayPriceWeekend

  const isTODPriceWeekdaySame = timeOfDayPriceWeekday.every((value) => value === timeOfDayPriceWeekday[0])
  const isTODPriceWeekendSame = timeOfDayPriceWeekend.every((value) => value === timeOfDayPriceWeekend[0])

  const isTODPriceWeekdayAllZero = timeOfDayPriceWeekday.every((value) => value === 0)
  const isTODPriceWeekendAllZero = timeOfDayPriceWeekend.every((value) => value === 0)

  const areWeekdayAndWeekendPricesSame = timeOfDayPriceWeekday[0] === timeOfDayPriceWeekend[0]

  const sameTODValueAndNotZero =
    isTODPriceWeekdaySame &&
    isTODPriceWeekendSame &&
    areWeekdayAndWeekendPricesSame &&
    !isTODPriceWeekdayAllZero &&
    !isTODPriceWeekendAllZero

  if (isTODPriceWeekdayAllZero && isTODPriceWeekendAllZero) {
    return <FreeListing isLargeScreen={isLargeScreen} isSmallScreen={isSmallScreen} />
  } else if (sameTODValueAndNotZero) {
    return (
      <>
        {formatCentsPrice(timeOfDayPriceWeekday[0])}/{isItHourOrkWh(listing.price.priceType)}
        {flatFee > 0 ? `+ ${formatPrice(flatFee)} ${feeText}` : ''}
      </>
    )
  } else {
    return (
      <div className={isLargeScreen}>
        {(!isTODPriceWeekdaySame || !isTODPriceWeekendSame || !areWeekdayAndWeekendPricesSame) &&
          !isTODPriceWeekdayAllZero &&
          !isTODPriceWeekendAllZero && (
            <>
              <Typography.Text
                onClick={() => showTimeOfDayModal(listing)}
                className={`${isSmallScreen} pointer require-underline`}
              >
                {flatFee > 0 ? `${timeOfDayText} + ${formatPrice(flatFee)} ${feeText}` : timeOfDayText}
              </Typography.Text>
            </>
          )}
      </div>
    )
  }
}
