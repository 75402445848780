import { Button, Form, Modal } from 'antd'
import { useNotifications } from 'hooks/useNotification'
import React, { useEffect, useState } from 'react'
import { AddUsersToDiscounts } from 'services/data-provider/discount'
import { sendSVG } from '../../assets/svg/send'
import { StyledDrawer } from '../../atom/drawer'
import { HorizontalLine } from '../../atom/horizontal-line'
import {
  ButtonTextSpacing,
  FooterLayout,
  Heading,
  HeadingDescription,
  MobileLineContainer,
} from '../../atom/user-invite'
import { AlertError } from '../../components/error'
import { useUserTranslation } from '../../hooks/translation/useUserTranslation'
import { SwtchError } from '../../models/error'
import { User, UserInviteInfo, UserPermissions } from '../../models/user'
import { NewInviteUser } from '../../services/data-provider/users'
import { useAppState } from '../../state'
import EditForm from './modal/user-edit-form'
import UserEmail from './modal/user-email'
import { AccessPlanObj } from './user-edit-redesign'

interface UserInvitationProps {
  onCancel: (e: React.FormEvent<EventTarget>) => void
  onUserInvited: (user: User, isMockUpEnabled: boolean) => void
}

export const UserInvitationRedesign: React.FC<UserInvitationProps> = ({ onCancel, onUserInvited }) => {
  const { currentUser, IsMobile, isMockUpEnabled } = useAppState()
  const isMobile = IsMobile()
  const [form] = Form.useForm()
  const [error, setError] = useState<SwtchError>()
  const [selectedUsers, setSelectedUsers] = useState<UserInviteInfo[]>([])
  const [loading, setLoading] = useState(false)
  const [validateStatus, setValidateStatus] = useState<string>('')
  const [disableSendInviteButton, setDisableSendInviteButton] = useState(true)
  const [userPermissions, setUserPermissions] = useState<UserPermissions[]>([
    { name: '', id: '', access: '', driver: false },
  ])
  const [isManager, setIsManager] = useState(false)

  const [allAccessPlans, setAllAccessPlans] = useState<AccessPlanObj>({})
  const [permissionCheck, setPermissionCheck] = useState(false)

  const isAdmin = currentUser?.role === 'admin'

  const { openSuccessNotification } = useNotifications()
  const { heading, headingInfo, sendInvite, cancelInviteText } = useUserTranslation()

  useEffect(() => {
    let manager = false
    currentUser?.accesses.map((access) => {
      access.permissions.map((permission) => {
        if (permission === 'tenant.manager' || permission === 'tenant.charger_operator') manager = true
        return manager
      })
      return manager
    })
    setIsManager(manager)
  }, [])

  const updated = () => setPermissionCheck(!permissionCheck)

  useEffect(() => {
    const disable = userPermissions.some((permission) => !permission.name || !permission.access || !permission.id)
    setDisableSendInviteButton(disable)
  }, [permissionCheck])

  useEffect(() => {
    form.validateFields(['email'])
  }, [form.getFieldValue('email')])

  const afterClose = () => {
    form.resetFields()
  }

  const onOk = () => {
    if (userPermissions[0].name === '') return
    const tempSelectedUsersArr = selectedUsers.map(({ id, email, name }) => ({
      id,
      email,
      name,
    }))
    const selectedTenants = userPermissions.map(({ name, id, access, driver }) => ({ name, id, access, driver }))
    const selectedAccessPlanIds = userPermissions.flatMap(({ accessPlan }) => accessPlan || []).map(({ id }) => id)

    NewInviteUser(selectedTenants, tempSelectedUsersArr, selectedAccessPlanIds, isMockUpEnabled)
      .then((invitedUser) => {
        openSuccessNotification(`${tempSelectedUsersArr[0].email} has been invited`)

        if (isAdmin || isManager) {
          const selectedLocalDiscounts = userPermissions
            .flatMap(({ discountPlan }) => discountPlan || [])
            .map(({ id, name }) => ({ id, name }))

          const users = selectedUsers.map(({ email }) => ({ email }))

          AddUsersToDiscounts(isMockUpEnabled, selectedLocalDiscounts, users)
            .then((res) => {})
            .catch((err) => setError(err))
            .finally(() => setLoading(false))
        }
        return onUserInvited(invitedUser, isMockUpEnabled)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <AlertError error={error} />
      {!isMobile ? (
        <Modal
          title={
            <>
              <Heading>{heading}</Heading>
              <HeadingDescription>{headingInfo}</HeadingDescription>
            </>
          }
          closable={true}
          visible={true}
          afterClose={afterClose}
          onCancel={onCancel}
          onOk={onOk}
          width={543}
          footer={
            <FooterLayout disabled={disableSendInviteButton}>
              <Button style={{ width: '240px' }} onClick={onCancel}>
                <span className="paragraph-02-regular">{cancelInviteText}</span>
              </Button>
              <Button style={{ width: '240px' }} disabled={disableSendInviteButton} type="primary" onClick={onOk}>
                <ButtonTextSpacing>
                  <span className="paragraph-02-regular">{sendInvite}</span>
                </ButtonTextSpacing>
                {sendSVG}
              </Button>
            </FooterLayout>
          }
        >
          <Form form={form} layout="vertical">
            <UserEmail
              selectedUsers={selectedUsers}
              validateStatus={validateStatus}
              loading={loading}
              setValidateStatus={setValidateStatus}
              setSelectedUsers={setSelectedUsers}
              setLoading={setLoading}
            />
            {selectedUsers.length > 0 && (
              <EditForm
                userPermissions={userPermissions}
                loading={loading}
                allAccessPlans={allAccessPlans}
                setUserPermissions={setUserPermissions}
                setAllAccessPlans={setAllAccessPlans}
                isNormalUser={!isAdmin && !isManager}
                updated={updated}
              />
            )}
          </Form>
        </Modal>
      ) : (
        <StyledDrawer
          placement="bottom"
          closable={false}
          onClose={onCancel}
          visible={true}
          key="bottom"
          footer={
            selectedUsers.length > 0 ? (
              <FooterLayout disabled={disableSendInviteButton}>
                <Button style={{ width: '100%' }} disabled={disableSendInviteButton} type="primary" onClick={onOk}>
                  <ButtonTextSpacing>
                    <span className="paragraph-02-regular">{sendInvite}</span>
                  </ButtonTextSpacing>
                  {sendSVG}
                </Button>
              </FooterLayout>
            ) : null
          }
          footerStyle={{ boxShadow: '0px -3px 5px 0px rgba(0, 0, 0, 0.06)', padding: '25px 15px' }}
        >
          <MobileLineContainer />
          <Heading>{heading}</Heading>
          <HeadingDescription>{headingInfo}</HeadingDescription>
          <HorizontalLine />
          <Form form={form} labelAlign="right" layout="vertical">
            <UserEmail
              selectedUsers={selectedUsers}
              validateStatus={validateStatus}
              loading={loading}
              setValidateStatus={setValidateStatus}
              setSelectedUsers={setSelectedUsers}
              setLoading={setLoading}
            />
            {selectedUsers.length > 0 && (
              <>
                <HorizontalLine />
                <EditForm
                  userPermissions={userPermissions}
                  loading={loading}
                  allAccessPlans={allAccessPlans}
                  setUserPermissions={setUserPermissions}
                  setAllAccessPlans={setAllAccessPlans}
                  updated={updated}
                />
              </>
            )}
          </Form>
        </StyledDrawer>
      )}
    </>
  )
}
