import { Form } from 'antd'
import { useCallback, useState } from 'react'

import { Tenant, TenantPartner, TenantPayload } from 'models/tenant'
import { useNotifications } from './useNotification'
import { usePlatformTranslation } from './translation/usePlatformTranslation'
import { useAppState } from 'state'

export const useTenantTabPlatform = (tenant: Tenant, onSave: (tenant: TenantPayload) => void) => {
  const { isMockUpEnabled } = useAppState()
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [isDuplicate, setIsDuplicate] = useState(false)

  const [form] = Form.useForm()

  const [changedIndexes, setChangedIndexes] = useState<number[]>([])

  const { openSuccessNotification, openWarningNotification } = useNotifications()
  const { platformSuccessText } = usePlatformTranslation()

  const handleToggleDisabled = useCallback(() => {
    setDisabled(!disabled)
    form.resetFields()
  }, [disabled])

  const setDeletedNames = (name: number) => {
    const currentChangedIndexes = [...changedIndexes].filter((index) => index !== name)
    currentChangedIndexes.push(name)
    setChangedIndexes(currentChangedIndexes)
  }

  const handlePartnerOptionChange = useCallback(
    (name: number) => (selectedPartner: { partnerId: number; partnerName: string; pmsCode?: string }) => {
      // update the form with the selected partner
      if (!selectedPartner) {
        return
      }
      const { partnerId, partnerName, pmsCode } = selectedPartner

      form.setFields([
        {
          name: ['tenant_partners', name, 'partnerId'],
          value: partnerId,
        },
        {
          name: ['tenant_partners', name, 'partnerName'],
          value: partnerName,
        },
        {
          name: ['tenant_partners', name, 'pmsCode'],
          value: pmsCode,
        },
      ])

      form.setFieldsValue({ [`tenant_partners[${name}].pmsCode`]: undefined })

      // Check for duplicates
      const partners = form.getFieldValue('tenant_partners')
      const partnerNames = partners.map((partner: TenantPartner) => partner.partnerName)
      const hasDuplicates = partnerNames.some((name: string, index: number) => partnerNames.indexOf(name) !== index)

      setIsDuplicate(hasDuplicates)
      if (hasDuplicates) {
        openWarningNotification('Duplicate partners are not allowed.')
      }
      form.validateFields()
    },
    [form],
  )

  const handleFormPlatformSubmit = async (values: TenantPayload) => {
    const payload: TenantPayload = {
      ...values,
      id: tenant.id,
    }

    onSave(payload)
    setDisabled(true)
    openSuccessNotification(platformSuccessText)
  }

  return {
    form,
    loading,
    disabled,
    isDuplicate,
    changedIndexes,
    setLoading,
    handleFormPlatformSubmit,
    handleToggleDisabled,
    handlePartnerOptionChange,
    setDeletedNames,
    isMockUpEnabled,
  }
}
