import { BaseLocal } from './base'
import configProvider from '../config'

const currentSiteOwner = configProvider.config.siteTitle

export const French: BaseLocal = {
  /*************************** General ***************************/
  'dashboard.title': 'Tableau de bord',
  'dashboard.title.admindashboard': 'Tableau de bord administrateur',
  'dashboard.title.supportdashboard': "Tableau de bord d'assistance",
  'dashboard.calendar.today': "Aujourd'hui",
  'dashboard.calendar.yesterday': 'Hier',
  'dashboard.calendar.past7days': '7 derniers jours',
  'dashboard.calendar.past15days': '15 derniers jours',
  'dashboard.calendar.weektodate': 'Semaine à ce jour',
  'dashboard.calendar.monthtodate': 'Mois à ce jour',
  'dashboard.calendar.yeartodate': 'Année à ce jour',
  'dashboard.calendar.lastweek': 'La semaine dernière',
  'dashboard.calendar.lastmonth': 'Le mois dernier',
  'dashboard.calendar.lastquarter': 'Dernier quart',
  'dashboard.calendar.customdate': 'Date personnalisée',
  'dashboard.calendar.thisWeek': 'Cette semaine',
  'dashboard.calendar.thisMonth': 'Ce mois-ci',
  'dashboard.calendar.thisYear': 'Cette année',
  'dashboard.calendar.lastYear': `L'année dernière`,
  'dashboard.calendar.previousQuarter': 'Trimestre précédent',
  'dashboard.error.somethingwentwrong': "Quelque chose s'est mal passé",
  'dashboard.text.connectivity': 'Connectivité',
  'dashboard.text.noConnection': 'Pas de connection',
  'dashboard.text.active': 'Actif',
  'dashboard.text.activityTracking': "Suivi d'activité",
  'dashboard.text.access': 'Accès',
  'dashboard.text.accessPlan': "Plan d'accès",
  'dashboard.text.carCatelog': 'Catalogue de voitures',
  'dashboard.text.country': 'Pays',
  'dashboard.text.on': 'au',
  'dashboard.text.close': 'close',
  'dashboard.text.connected': 'Connecté',
  'dashboard.text.disconnected': 'Débranché',
  'dashboard.text.energyConsumedWithkWh': 'Énergie consommée (kWh)',
  'dashboard.text.filter': 'Filtre',
  'dashboard.selected': 'choisi',
  'dashboard.button.delete': 'Supprimer',
  'dashboard.text.masterAccount': 'Compte principal',
  'dashboard.text.account': 'Compte',
  'dashboard.text.channelPartner': 'Partenaire de canal',
  'dashboard.text.generated': 'Généré',
  'dashboard.text.hour': 'Heure',
  'dashboard.text.by': 'Par',
  'dashboard.text.billingAddress': 'Adresse de facturation',
  'dashboard.button.create': 'Créer',
  'dashboard.button.city': 'Ville',
  'dashboard.button.country': 'Les Pays',
  'dashboard.text.currency': 'Devise',
  'dashboard.text.description': 'La description',
  'dashboard.button.subscribe': "S'abonner",
  'dashboard.button.cancel': 'Annuler',
  'dashboard.text.charger': 'Chargeur',
  'dashboard.text.chargeDuration': 'Durée des frais',
  'dashboard.text.viewCharger': 'Voir le chargeur',
  'dashboard.text.viewMore': 'Voir plus',
  'dashboard.text.search': 'Chercher',
  'dashboard.text.modify': 'Modifier',
  'dashboard.text.refresh': 'Actualiser',
  'dashboard.text.action': 'Action',
  'dashboard.text.details': 'Details',
  'dashboard.text.glossaryFor': 'Glossaire du',
  'dashboard.text.found': 'trouvé',
  'dashboard.text.heading': 'Titre',
  'dashboard.text.is': 'est',
  'dashboard.text.are': 'sont',
  'dashboard.text.inactive': 'Inactif',
  'dashboard.text.interval': 'Intervalle',
  'dashbaord.text.installateur': 'Installer',
  'dashboard.text.configure': 'Configurer',
  'dashboard.text.listing': 'Référencement',
  'dashboard.text.listingLower': 'référencement',
  'dashboard.text.listingID': "Numéro d'annonce",
  'dashboard.text.name': 'Nom',
  'dashboard.text.netRevenue': 'Revenu net',
  'dashboard.text.numberOfTransactions': 'Nombre de transactions',
  'dashboard.text.note': 'Remarque',
  'dashboard.text.status': 'Statut',
  'dashboard.text.online': 'En ligne',
  'dashboard.text.offline': 'Hors ligne',
  'dashboard.text.panel': 'Panneau',
  'dashboard.text.plans': 'Des Plans',
  'dashboard.text.plan': 'Le Plan',
  'dashboard.text.postalCodeZipCode': 'Code postal',
  'dashboard.text.price': 'Prix',
  'dashboard.text.provinceState': 'Province/État',
  'dashboard.text.enable': 'Activer',
  'dashboard.text.enabled': 'Activé',
  'dashboard.text.disabled': 'Infirme',
  'dashboard.text.cancel': 'Annuler',
  'dashboard.text.apply': 'Appliquer',
  'dashboard.text.cancelled': 'Annulé',
  'dashboard.text.paused': 'En pause',
  'dashboard.text.save': 'Sauver',
  'dashboard.text.saveChanges': 'Sauvegarder les modifications',
  'dashbaord.text.interval': 'Intervalle',
  'dashboard.text.state': 'État',
  'dashboard.text.streetAddress': 'Adresse de rue',
  'dashboard.text.subscribers': 'Les Abonnés',
  'dashboard.text.subscription': 'abonnement',
  'dashboard.text.subscriptions': 'Abonnements',
  'dashboard.text.user': 'Utilisateur',
  'dashboard.text.users': 'Utilisateurs',
  'dashboard.text.add': 'Ajouter',
  'dashboard.text.edit': 'Éditer',
  'dashboard.text.tenant': 'Site',
  'dashboard.text.vendor': 'Vendeur',
  'dashboard.text.start': 'Démarrer',
  'dashboard.text.stop': 'Arrêter',
  'dashboard.text.update': 'Mise à jour',
  'dashboard.text.error': 'Erreur',
  'dashboard.text.cost': 'Le Coût',
  'dashboard.text.location': 'Emplacement',
  'dashboard.text.searchlocation': 'Emplacement de recherche',
  'dashboard.text.map&access': 'Plan & Accès',
  'dashboard.text.loiteringStatuses': 'Statuts de vagabondage',
  'dashboard.text.all': 'Tout',
  'dashboard.text.weekday': 'Jour de la semaine',
  'dashboard.text.weekend': 'Fin de semaine',
  'dashboard.text.everyday': 'Tous les jours',
  'dashboard.text.level2': 'Niveau 2',
  'dashboard.text.noDataAvailable': 'Désolé, aucune donnée à afficher.',
  'dashboard.text.unknown': 'Inconnue',
  'dashboard.text.switchHomePage': 'Passer à la vue classique du tableau de bord',
  'dashboard.text.switchUsersPage': 'Passer à la page des utilisateurs classiques',
  'dashboard.text.switchHomePageNewVersion': 'Passer à la nouvelle vue du tableau de bord',
  'dashboard.text.switchUsersPageNewVersion': 'Passer à la page des nouveaux utilisateurs',
  'dashboard.text.transactions': 'Transactions',
  'dashboard.text.transaction': 'Transaction',
  'dashboard.text.warning': 'Avertissement',
  'dashboard.text.noDataFor': "Désolé, aucune donnée n'a pu être obtenue avec ",
  'dashboard.text.daily': 'Tous les jours',
  'dashboard.text.schema': 'Schéma',
  'dashboard.text.pricingSchema': 'Schéma de tarification',
  'dashboard.text.select': 'Sélectionner',
  'dashboard.text.fee': 'Frais',
  'dashboard.text.suspended': 'Suspendu',
  'dashboard.text.deleted': 'Supprimé',
  'dashboard.text.draft': 'Brouillon',
  'dashboard.text.pending_sa': 'En attente de SA',
  'dashboard.text.onHold': 'En Attente',
  'dashboard.text.on_hold': 'En Attente',
  'dashboard.text.configuration': 'Configuration',
  'dashboard.text.directDepositInformation': 'Information Sur Le Dépôt Direct',
  'dashboard.text.direct_deposit_information': 'Information Sur Le Dépôt Direct',
  'dashboard.text.onboardingComplete': 'Intégration Terminée',
  'dashboard.text.onboarding_complete': 'Intégration Terminée',
  'dashboard.text.statuses': 'Statut (statuts)',
  'dashboard.text.accountName': 'Nom du compte (noms de compte)',
  'dashboard.text.archive': 'Archive',
  'dashboard.heading.realTimeData': 'Données en temps réel',
  'dashboard.heading.historicalData': 'Données historiques',
  'dashboard.text.chargerId': 'Identifiant du chargeur',
  'dashboard.text.chargerIds': 'Identifiant(s) du chargeur',
  'dashboard.text.makeAndModel': 'Marque et modèle',
  'dashboard.text.yes': 'Oui',
  'dashboard.text.no': 'Non',
  'dashboard.text.viewChargerDetails': 'Afficher les détails du chargeur',
  'dashboard.text.viewSiteDetails': 'Afficher les détails du site',
  'dashboard.text.noLoiteringPenalty': 'Amende pour interdiction de stationnement prolongé',
  'dashboard.text.minute': 'Minute',
  'dashboard.text.more': 'Plus',
  'dashboard.text.incidences': 'Incidents',
  'dashboard.text.continue': 'CONTINUER',
  'dashboard.text.unrestricted': 'Illimité',
  'dashboard.text.restricted': 'Restreint',

  /*************************** Time ***************************/
  'time.years': '{count, plural, one {an} other {ans}}',
  'time.months': '{count, plural, one {mois} other {mois}}',
  'time.weeks': '{count, plural, one {sem} other {sems}}',
  'time.days': '{count, plural, one {j} other {j}}',
  'time.hours': '{count, plural, one {h} other {h}}',
  'time.minutes': '{count, plural, one {min} other {mins}}',
  'time.seconds': '{count, plural, one {s} other {s}}',
  'time.duration.weeks': '{count} semaine{count, plural, one {} other (s)}',
  'time.duration.months': '{count} mois',
  'time.duration.years': '{count} an{count, plural, one {} other (s)}',

  /*************************** Car ***************************/
  'dashboard.text.brand': 'Marque',
  'dashboard.text.model': 'Modèle',
  'dashboard.text.year': 'Année',
  'dashboard.text.socBeforeCharging': 'État de charge avant la charge',
  'dashboard.text.socAfterCharging': 'État de charge après la charge',

  /************************* Listing *************************/
  'dashboard.text.listingTitles': "Titre(s) de l'annonce",
  'dashboard.text.generalPrice': 'Prix ​​général',
  'dashboard.text.preferredPrice': 'Prix ​​préférentiel',
  'dashboard.text.smartCharging': 'Chargement intelligent',
  'dashboard.text.fundingSources': 'Sources de financement',
  'dashboard.text.trailerAccessible': 'Borne de recharge accessible par véhicule avec remorque',
  'dashboard.text.fundingSourcesPlaceholder': 'Sélectionnez la ou les sources de financement',
  'dashboard.text.selectAllListings': 'Sélectionner tous les listings',
  'dashboard.text.editListingDetails': "Modifier les détails de l'annonce",
  'dashboard.text.viewChargerInApp': "Afficher le chargeur dans l'application",
  'dashboard.text.editListing': "Modifier l'annonce",

  /************************* Listing Modal *************************/
  'dashboard.listingPage.modal.updateListing': 'Mettre à jour la liste',
  'dashboard.listingPage.modal.settlement': 'Règlement',
  'dashboard.chargersPage.modal.updateListing.selected': 'sélectionné',
  'dashboard.listingPage.modal.decommisioned.reminder': 'Rappel',
  'dashboard.chargersPage.modal.decommisioned.description':
    'Veuillez noter que changer le statut du service de listing en Désactivé mettra également à jour les éléments suivants :',
  'dashboard.chargersPage.modal.decommisioned.option1.left': '• Accès -',
  'dashboard.chargersPage.modal.decommisioned.option1.right': 'Restreint',
  'dashboard.chargersPage.modal.decommisioned.option2.left': '• Masqué sur la carte ',
  'dashboard.chargersPage.modal.decommisioned.option2.right': 'Vrai',
  'dashboard.chargersPage.modal.decommisioned.acknowledge': 'Reconnaître',
  'dashboard.chargersPage.modal.decommisioned.cancel': 'Annuler',
  'dashboard.chargersPage.modal.updateListing.update': 'Mettre à jour',
  'dashboard.chargersPage.modal.updateListing.listingUpdated': 'Annonce mise à jour',
  'dashboard.chargersPage.modal.updateListing.listingsUpdated': 'Annonces mises à jour',
  'dashboard.listingPage.modal.bulkUpdate.swtchCare': 'SWTCH Care',
  'dashboard.listingPage.modal.bulkUpdate.buildingWifi': 'Wi-Fi du bâtiment',
  'dashboard.listingPage.modal.bulkUpdate.currentStatus': 'Statut actuel',
  'dashboard.listingPage.modal.bulkUpdate.since': '-1 depuis -2',
  'dashboard.listingPage.modal.bulkUpdate.about': 'À propos de la liste sélectionnée : -1',
  'dashboard.listingPage.modal.bulkUpdate.issueDetails': 'Détails du problème',
  'dashboard.listingPage.modal.bulkUpdate.issueSymptom': 'Symptôme du problème',
  'dashboard.listingPage.modal.bulkUpdate.details': 'Détails',
  'dashboard.listingPage.modal.bulkUpdate.serviceEvent': 'Événement de service',
  'dashboard.listingPage.modal.bulkUpdate.exclude': 'Exclure',
  'dashboard.listingPage.modal.bulkUpdate.include': 'Inclure',
  'dashboard.listingPage.modal.bulkUpdate.resolution': 'Résolution',
  'dashboard.listingPage.modal.bulkUpdate.rootCause': 'Cause principale',
  'dashboard.listingPage.modal.bulkUpdate.zendeskId': 'ID Zendesk',
  'dashboard.listingPage.modal.bulkUpdate.uptimeReport': 'Rapport de disponibilité',
  'dashboard.listingPage.modal.bulkUpdate.statusChange': 'Changement de statut',
  'dashboard.listingPage.modal.bulkUpdate.warning':
    'Les annonces ne peuvent être modifiées que si elles partagent le même statut de site et de service',
  'dashboard.listingPage.modal.bulkUpdate.help':
    'Pour la liste sélectionnée ci-dessus, le symptôme enregistré lorsque le statut a été changé en Réparation est : Hors ligne',
  'dashboard.listingPage.modal.bulkUpdate.newStatusSince': 'Nouveau statut depuis',
  'dashboard.listingPage.modal.bulkUpdate.underRepairSince': 'En réparation depuis',
  'dashboard.listingPage.modal.bulkUpdate.notes': 'Notes',
  'dashboard.text.carAndMapSettings': 'Paramètres de voiture et de carte',

  /************************* Only for Listing Modal because it is used as part of a sentence *************************/
  'dashboard.chargersPage.modal.updateListing.from': 'de',

  /************************* Loitering *************************/
  'dashboard.text.loitering': 'Traîner',
  'dashboard.text.visibleOnMap': 'Visible sur la carte',
  'dashboard.text.hiddenFromMap': 'Caché de la carte',

  /************************* Selector *************************/
  'dashboard.text.selected.serialNumber': 'Numéros De Série Sélectionnés',
  'dashboard.text.selected.tenant': '-1 Sites sélectionnés',

  /***************** Connector Service Status *****************/
  'dashboard.serviceStatus.awaitingCommissioning': 'En attente de mise en service',
  'dashboard.serviceStatus.awaiting_commissioning': 'En attente de mise en service',
  'dashboard.serviceStatus.decommissioned': 'Désaffectée',
  'dashboard.serviceStatus.commissioned': 'Commandé',
  'dashboard.serviceStatus.testing': 'Essai',
  'dashboard.serviceStatus.active': 'Actif',
  'dashboard.serviceStatus.property_activating': 'Activation - Propriété en attente',
  'dashboard.serviceStatus.driver_activating': 'Activation - du conducteur en attente',
  'dashboard.serviceStatus.activatingPendingProperty': 'Activation - Propriété en attente',
  'dashboard.serviceStatus.under_repair': 'En réparation',
  'dashboard.serviceStatus.onHold': 'En attente',
  'dashboard.serviceStatus.activatingPendingDriverSub': 'Activation - Abonnement du conducteur en attente',
  'dashboard.serviceStatus.other': 'Autre',
  'dashboard.serviceStatus.troubleshooting': 'Dépannage',
  'dashboard.serviceStatus.terminated': 'Résilié',
  'dashboard.serviceStatus.selectReason': 'Sélectionnez une raison',
  'dashboard.serviceStatus.selectReasonForStatusChange': 'Sélectionnez la raison du changement de statut',
  'dashboard.serviceStatus.chargerIsOffline': 'Le chargeur est hors ligne',
  'dashboard.serviceStatus.chargerIsFaulted': 'Le chargeur est défectueux',
  'dashboard.serviceStatus.chargerIsDamaged': 'Le chargeur est endommagé',
  'dashboard.serviceStatus.chargerIsVandalised': 'Le chargeur est vandalisé',
  'dashboard.serviceStatus.stationIsMalfunctioning': 'La station fonctionne mal',
  'dashboard.serviceStatus.otherPleaseSpecify': 'Autre - veuillez préciser',
  'dashboard.serviceStatus.priceIsNotCompetitive': 'Le prix n’est pas compétitif',
  'dashboard.serviceStatus.lackOfFeature': 'Manque de fonctionnalité',
  'dashboard.serviceStatus.productBug': 'Bogue du produit',
  'dashboard.serviceStatus.poorCustomerService': 'Mauvais service à la clientèle',
  'dashboard.serviceStatus.poorHardwareQuality': 'Mauvaise qualité du matériel',
  'dashboard.serviceStatus.unitReplacement': 'Remplacement d’unité',
  'dashboard.serviceStatus.cableReplacement': 'Remplacement de câble',
  'dashboard.serviceStatus.hardwarePartsReplacementDCFC': 'Remplacement de pièces matérielles (DCFC)',
  'dashboard.serviceStatus.breakerReset': 'Réinitialisation du disjoncteur',
  'dashboard.serviceStatus.networkReset': 'Réinitialisation du réseau',
  'dashboard.serviceStatus.networkHardwareReplacement': 'Remplacement du matériel réseau',
  'dashboard.serviceStatus.specificANewReason': 'Indiquer une nouvelle raison',
  'dashboard.serviceStatus.typeAReason': 'Écrire une raison',
  'dashbaord.serviceStatus.initialActivation': 'Activation initiale',

  /************************** Placeholder **************************/
  'dashboard.placeholder.masterAccount': 'Sélectionnez un compte principal',
  'dashboard.placeholder.pleaseEnterPartner': 'Veuillez saisir un partenaire',
  'dashboard.placeholder.addNewMasterAccount': 'Ajouter un nouveau compte principal',
  'dashboard.text.addPlatform': 'Ajouter une plateforme',

  /*********************** General Messasge ***********************/
  'dashboard.message.positiveNumber': 'Veuillez entrer un nombre positif',
  'dashboard.placeholder.searchEmail': 'Chercher un email',
  /*************************** Navigation ***************************/
  'dashboard.navigation.dashboard': 'Tableau de bord',
  'dashboard.navigation.chargers': 'Chargeurs',
  'dashboard.navigation.transactions': 'Transactions',
  'dashboard.navigation.tenants': 'Sites',
  'dashboard.navigation.listings': 'Annonces',
  'dashboard.navigation.analytics': 'Analytiques',
  'dashboard.navigation.reports': 'Rapports',
  'dashboard.navigation.users': 'Utilisateurs',
  'dashboard.navigation.peakShaving': 'Gestion de la demande',
  'dashboard.navigation.tagging': 'Étiquetage',

  /*********************** Pagination ***********************/
  'dashboard.pagination.previous': 'Précédent',
  'dashboard.pagination.next': 'Suivant',

  /*************************** Header ***************************/
  'dashboard.header': 'Tableau de bord administrateur',
  'dashboard.header.logout': 'Se déconnecter',
  'dashboard.header.chooseLanguage': 'Choisissez la langue',

  /*************************** Footer ***************************/
  'dashboard.footer.poweredby': 'Produite par',

  /************************ Export and Search Button *********************/
  'dashboard.exportbtn.heading': 'Exportation',
  'dashboard.exportbtn.title': 'Exporter des données',
  'dashboard.exportbtn.csv': 'CSV',
  'dashboard.exportbtn.pdf': 'PDF',
  'dashboard.exportbtn.info': "Choisissez d'exporter les données via PDF ou CSV. Le dossier sera envoyé par mail à",
  'dashboard.searchBtn.title': 'Chercher',
  'dashboard.search.placeholder.user': 'Rechercher un utilisateur',

  /*************************** Selector ***************************/
  'dashboard.selector.searchTenant': 'Rechercher un site',
  'dashboard.selector.Tenants': 'Des Sites',
  'dashboard.selector.searchTenants': 'Rechercher site(s)',
  'dashboard.selector.searchCharger': 'Rechercher un chargeur',
  'dashboard.selector.searchListing': 'Liste de recherche',
  'dashboard.selector.selectConnectorStatus': 'État du connecteur',
  'dashboard.selector.serviceStatus': 'État du service',
  'dashboard.selector.transactionID': 'Identifiant de transaction',
  'dashboard.selector.startTyping': 'Commencez à taper pour rechercher',
  'dashboard.selector.vendors': 'Vendeur(s)',
  'dashboard.selector.makes': 'Fabriquer',

  /*************************** Charger ***************************/
  'dashboard.charger.updateAllChargers': 'Modifier les tarifs pour TOUS les chargeurs du plan',
  'dashboard.charger.updateThisChargers': 'Modifier les tarifs pour CE chargeur',
  'dashboard.charger.phases': 'Phases électriques',
  'dashboard.charger.minAmp': 'Capacité Min.',
  'dashboard.charger.maxAmp': 'Capacité Max.',
  'dashboard.charger.fallback': 'Repli',
  'dashboard.charger.circuitSize': 'Taille du Circuit',
  'dashboard.charger.existingLoad': 'Charge Existante',
  'dashboard.charger.breakerSize': 'Taille du Disjoncteur',
  'dashboard.charger.endPoint': 'Point Final',
  'dashboard.charger.type.mixed': 'Mixte',
  'dashboard.charger.type.dedicated': 'Dédié',
  'dashboard.charger.serviceStatus.active': 'Actif',
  'dashboard.charger.serviceStatus.commissioning': 'Mise en service',
  'dashboard.charger.serviceStatus.activating': 'Activation',
  'dashboard.charger.serviceStatus.underRepair': 'En réparation',
  'dashboard.charger.serialNumber': 'Numéro de série',
  'dashboard.charger.serialNumbers': 'Numéros de série',
  'dashboard.charger.vendor': 'Vendeur',
  'dashboard.charger.model': 'Modèle',
  'dashboard.charger.chargerType': 'Type de chargeur',
  'dashboard.charger.SN#': 'Numéro SN',
  'dashboard.charger.chargerId': 'ID du chargeur',
  'dashboard.charger.swtchCare': 'SWTCH Care',
  'dashboard.charger.utilizationRate': "Taux d'utilisation",
  'dashboard.charger.uptimePercentage': 'Pourcentage de disponibilité',
  'dashboard.charger.chargerIds': 'Identifiant(s) du chargeur',
  'dashboard.text.selectAllChargers': 'Sélectionner les {totalEntries} chargeurs',
  'dashboard.text.allChargersSelected': 'Tous les {count} chargeurs de cette page sont sélectionnés',
  'dashboard.text.someChargersSelected':
    '{count} {chargerCount, plural, one {chargeur sélectionné} other {chargeurs sélectionnés}} de cette page',
  'dashboard.text.map.description': 'Cliquez sur un chargeur pour afficher les détails',

  /*************************** Connector ***************************/
  'dashboard.connector.connectorId': 'Identifiant du connecteur',
  'dashboard.connector.lastStatusChangedAt': 'Dernier statut modifié à',
  'dashboard.connector.type': 'Type de connecteur',
  'dashboard.text.connectorStatus.description': 'e dernier état de charge reçu du chargeur',
  'dashboard.text.connectivity.description': "L'état actuel de la connectivité du chargeur",

  /*************************** Tenant Selector Page ***************************/
  'dashboard.tenantselectorpage.pagereload': 'Chargement de votre profil',
  'dashboard.tenantselectorpage.sessionexpired': 'Session expirée',
  'dashboard.tenantselectorpage.redirect': 'Redirection vers la page de connexion!',
  'dashboard.tenantselectorpage.title': "Oups quelque chose s'est mal passée",
  'dashboard.tenantselectorpage.subtitle': 'Essayer à nouveau.',
  'dashboard.tenantselectorpage.backtologin': 'Retour à la page de connexion',
  'dashboard.tenantselectorpage.tenantpermission.title': "Vous n'avez pas accès à un Locataire",
  'dashboard.tenantselectorpage.tenantpermission.subtitle': 'Veuillez contacter un administrateur',

  /*************************** Auth0 Login Page ***************************/
  'dashboard.authpage.title': "Oups quelque chose s'est mal passé",
  'dashboard.authpage.subtitle': "Nous n'avons pas pu vous connecter. Réessayez",
  'dashboard.authpage.backtologin': 'Retour à la page de connexion',
  'dashboard.authpage.error,invalidcallbackparameters': 'Paramètres de rappel non valides',

  /*************************** Home Page ***************************/
  'dashboard.homePage.home': 'Accueil',
  'dashboard.homePage.selectTenant': 'Sélectionnez un locataire',
  'dashboard.homePage.search': 'Chercher',
  'dashboard.homePage.revenue': 'Revenu',
  'dashboard.homePage.revenues': 'revenus',
  'dashboard.homePage.transaction': 'Transaction',
  'dashboard.homePage.transactions': 'Transactions',
  'dashboard.homePage.transactions.info.p1': 'des transactions ont commencé après 18h',
  'dashboard.homePage.transactions.info.p2': 'des transactions ont été lancées en semaine',
  'dashboard.homePage.demandResponseText': 'de la réponse à la demande',
  'dashboard.homePage.chargingText': 'Mise en charge',
  'dashboard.homePage.fromChargingText': 'de la charge',
  'dashboard.homePage.loiteringText': 'de flâner',
  'dashboard.homePage.energy': 'Énergie',
  'dashboard.homePage.energy.info.p1': 'de charge a eu lieu pendant les heures de pointe',
  'dashboard.homePage.energyConsumptions': "Consommation d'énergie (kWh)",
  'dashboard.homePage.energyConsumptions.info.header': 'Infos sur les gaz à effet de serre',
  'dashboard.homePage.energyConsumptions.info.p1':
    'Comment mes économies de gaz à effet de serre sont-elles calculées?',
  'dashboard.homePage.energyConsumptions.info.p2':
    "Consommation de carburant ICE en 2017 basée sur un véhicule moyen au Canada : 8,9 L d'essence/100 km",
  'dashboard.homePage.energyConsumptions.info.p3':
    "Émissions de CO2 eq par litre d'essence pour ICE : 2,3 kg de CO2 eq/L d'essence",
  'dashboard.homePage.energyConsumptions.info.p4':
    'Consommation de carburant des VE basés sur le parc de véhicules électriques à batterie du Canada en 2020 : 19,51 kWh/100 km',
  'dashboard.homePage.energyConsumptions.info.p5': 'Émissions de CO2 eq par kWh au Canada : 318,9 g CO2 eq/kWh',
  'dashboard.homePage.energyConsumptions.info.p6': 'Au Canada',
  'dashboard.homePage.energyConsumptions.info.okbtn': "D'accord",
  'dashboard.homePage.ghgsavings': 'Économies de GES (kg C02 eq)',
  'dashboard.homePage.ghgsavings.text': 'Économies de GES',
  'dashboard.homePage.totalConnectors': 'Nombre total de connecteurs',
  'dashboard.homePage.inuse': 'Utilisé',
  'dashboard.homePage.available': 'Disponible',
  'dashboard.homePage.unavailable': 'Indisponible',
  'dashboard.homePage.commissioning': 'Mise en service',
  'dashboard.homePage.totalActive': 'Nombre total de connecteurs actifs',
  'dashboard.homePage.activating': "En cours d'activation",
  'dashboard.homePage.underRepair': 'En cours de réparation',
  'dashboard.homePage.info.totalActive':
    "Il s'agit de tous les connecteurs des blocs Disponible, En cours d'utilisation et Non disponible",
  'dashboard.homePage.info.totalActivating':
    "Connecteurs où l'état du service est défini sur Commissionné, Activation - Propriété en attente ou Activation - Abonnement de pilote en attente",
  'dashboard.homePage.info.totalCommissioning':
    "Connecteurs où l'état du service est défini sur En attente, En attente de mise en service ou Dépannage",
  'dashboard.homePage.stationoverview.heading': 'Aperçu de la station',
  'dashboard.homePage.stationoverview.activeUsers': 'Utilisateurs actifs',
  'dashboard.homePage.stationoverview.activeUsers.idle': 'Inactif',
  'dashboard.homePage.stationOverview.activeUsers.idle.info':
    'La transaction est en cours, mais le véhicule ne charge pas activement.',
  'dashboard.homePage.stationoverview.connectorsPluggedIn': 'Connecteurs branchés',
  'dashboard.homePage.stationoverview.connectorsPluggedIn.level2connectors': 'Niveau 2',
  'dashboard.homePage.stationoverview.connectorsPluggedIn.dcfastconnectors': 'Rapides CC',
  'dashboard.homePage.stationoverview.chargersPluggedIn': 'Chargeurs branchés',
  'dashboard.homePage.stationoverview.chargersParcipatingDREvents.heading':
    'Connecteurs inscrits dans la réponse à la demande',
  'dashboard.homePage.stationoverview.chargersParcipatingDREvents.level2connectors': 'Connecteurs de niveau 2',
  'dashboard.homePage.stationoverview.chargersParcipatingDREvents.dcfastconnectors': 'Connecteurs rapides CC',
  'dashboard.homePage.stationoverview.connectorAvailability': 'Disponibilité du connecteur',
  'dashboard.homePage.stationoverview.connectorAvailability.level2connectors': 'Connecteurs de niveau 2',
  'dashboard.homePage.stationoverview.connectorAvailability.dcfastconnectors': 'Connecteurs rapides CC',
  'dashboard.homePage.stationoverview.chargersAvailability': 'Disponibilité des chargeurs',
  'dashboard.homePage.operationoverview.heading': 'Présentation du fonctionnement',
  'dashboard.homePage.operationoverview.timeZone.heading': 'Heure (HNE)',
  'dashboard.homePage.utilizationInsights.heading': "Informations sur l'utilisation",
  'dashboard.homePage.utilizationInsights.dial.averageChargingDurationText': 'Durée de charge moy.',
  'dashboard.homePage.utilizationInsights.dial.averagePlugInDurationText': 'Durée de branchement moy.',
  'dashboard.homePage.utilizationInsights.numberOfConnectorsPluggedIn': 'Nombre de connecteurs branchés',
  'dashboard.homePage.consumptionInsights.heading': 'Informations sur la consommation',
  'dashboard.homePage.consumptionInsights.uniqueUsers.heading': 'Utilisateurs uniques',
  'dashboard.homePage.consumptionInsights.avgNumberOfTransactions.heading': '# moyen de transactions',
  'dashboard.homePage.consumptionInsights.avgNumberOfTransactions.day': 'jour',
  'dashboard.homePage.consumptionInsights.averageConsumption.averageUserSpend': 'Dépense moyenne par utilisateur',
  'dashboard.homePage.consumptionInsights.averageConsumption.heading': 'Consommation moyenne',
  'dashboard.homePage.consumptionInsights.chart.title': 'Profil de charge quotidienne moyenne',
  'dashboard.homePage.stationActivation.heading': 'Activation des stations',
  'dashboard.homePage.transactionOverview.heading': 'Aperçu des transactions',
  'dashboard.homePage.seeAllTransactions.heading': 'Voir toutes les opérations',
  'dashboard.homePage.stationoverview.stations.heading': 'Gares',
  'dashboard.homePage.stationoverview.current.heading': 'Actuel',
  'dashboard.homePage.stationoverview.stations.onlinechargers.text': 'Connecteurs en ligne',
  'dashboard.homePage.stationoverview.stations.offlinechargers.text': 'Connecteurs hors ligne',
  'dashboard.homePage.stationoverview.stations.underrepairchargers.text': 'En réparation',
  'dashboard.homePage.stationoverview.stations.onlinechargers.description':
    'Fonctionnel et connexion établie permettant la surveillance et la gestion à distance',
  'dashboard.homePage.stationoverview.stations.offlinechargers.description':
    "Impossible d'établir la connexion et peut nécessiter un dépannage supplémentaire ou une escalade",
  'dashboard.homePage.stationoverview.stations.underrepairchargers.description':
    "Actuellement hors service en raison d'une maintenance pour restaurer la fonctionnalité",
  'dashboard.homePage.stationoverview.current.description1': 'Courant de chargeur actif',
  'dashboard.homePage.stationoverview.current.description2': 'Courant maximal du chargeur',
  'dashboard.homePage.stationActivation.webapp': 'Application Web',
  'dashboard.homePage.stationActivation.roamingpartner': "Partenaire d'itinérance",
  'dashboard.homePage.stationActivation.mobileapp': 'Application mobile',
  'dashboard.homePage.exportGraph.pdf': 'Exportation au format PDF',
  'dashboard.homePage.exportGraph.csv': 'Exportation au format CSV',

  /*************************** Chargers Page ***************************/
  'dashboard.chargersPage.connector.heading': 'Connecteur',
  'dashboard.chargersPage.searchTenant': 'Rechercher un site',
  'dashboard.chargersPage.selectVendor': 'Sélectionnez le fournisseur',
  'dashboard.chargersPage.selectStatus': 'Sélectionnez le statut',
  'dashboard.text.initialActivationWarning':
    "Avertissement : Cliquer sur « Appliquer » mettra à jour la date de début de l'activation initiale à la date d'aujourd'hui",
  'dashboard.chargersPage.table.heading.connectorId': 'Identifiant du connecteur',
  'dashboard.chargersPage.table.heading.tenant': 'Site',
  'dashboard.chargersPage.table.heading.serviceStatus': 'État du service',
  'dashboard.chargersPage.table.heading.status': 'État du connecteur',
  'dashboard.chargersPage.modal.updatechargers': 'Mettre à jour les chargeurs',
  'dashboard.chargersPage.modal.updatechargers.selected': 'choisi',
  'dashboard.chargersPage.modal.firmware': 'Micrologiciel',
  'dashboard.chargersPage.modal.firmware.location': 'Emplacement',
  'dashboard.chargersPage.modal.firmware.location.warning': 'Veuillez saisir un emplacement!',
  'dashboard.chargersPage.modal.firmware.location.help':
    'Date après laquelle le chargeur doit avoir la (nouvelle) firmware',
  'dashboard.chargersPage.modal.firmware.retrieveDate': 'Date d`obtenation',
  'dashboard.chargersPage.modal.firmware.retrieveDate.warning': 'Veuillez obtenir une date!',
  'dashboard.chargersPage.modal.firmware.updatefirmware': 'Mise à jour du firmware',
  'dashboard.chargersPage.modal.configuration': 'Configuration',
  'dashboard.chargersPage.modal.configuration.key': 'Clé',
  'dashboard.chargersPage.modal.configuration.key.info': 'Clé de la valeur que vous souhaitez modifier',
  'dashboard.chargersPage.modal.configuration.key.warning': 'Veuillez saisir une clé ',
  'dashboard.chargersPage.modal.configuration.value': 'Valeur',
  'dashboard.chargersPage.modal.configuration.value.info': 'Valeur à modifier',
  'dashboard.chargersPage.modal.configuration.updateConfiguration': 'Mettre à jour la configuration',
  'dashboard.chargersPage.modal.configuration.updatefirmwarechanges': 'Envoi de la mise à jour du firmware pour',
  'dashboard.chargersPage.modal.configuration.trigger.heading':
    'Sélectionnez un message ci-dessous pour les chargeurs sélectionnés pour lancer un message de déclenchement',
  'dashboard.chargersPage.modal.configuration.trigger.message':
    "Envoi du message de déclenchement '123' aux chargeurs sélectionnés",
  'dashboard.text.chargerID.description': "ID unique que l'on peut trouver sur le devant d'un chargeur",
  'dashboard.text.serialNumber.description': 'Numéro de série unique attribué à chaque chargeur',
  'dashboard.text.makeAndModel.description': 'Marque et modèle du fabricant du chargeur',
  'dashboard.text.pricingPlan.description':
    "Détails du tarif d'utilisation fixé pour la facturation et si les réservations sont autorisées",
  'dashboard.text.loitering.description': "Détails si des frais de flânerie sont appliqués au tarif d'utilisation",
  'dashboard.text.access.description':
    "Indique si un chargeur est 'ouvert', ce qui signifie accessible au public, ou 'restreint', ce qui signifie qu'il est disponible uniquement pour les personnes ayant accès via un compte ou un code d'accès. Cette section détaille également le code d'accès le cas échéant et si le chargeur est visible ou masqué sur les cartes publiques",
  'dashboard.text.serviceStatus.description': `L'état actuel de la connectivité du chargeur sur le réseau ${currentSiteOwner}`,
  'dashboard.charger.makeAndModel': 'Marque et modèle',
  'dashboard.text.chargingStatus': 'État de charge',
  'dashboard.text.lastChargingStatus': 'Dernier état de charge',
  'dashboard.text.chargingStatusesSelected': '-1 États de charge sélectionnés',
  'dashboard.text.serviceStatusesSelected': '-1 États de service sélectionnés',
  'dashboard.text.accountNamesSelected': '-1 Noms de compte sélectionnés',
  'dashboard.text.tagsSelected': '-1 Étiquettes sélectionnées',

  /*************************** Charger Page ***************************/
  'dashboard.chargerPage.heading': 'Chargeur',
  'dashboard.chargerPage.allConnectors': 'Tous les connecteurs',
  'dashboard.chargerPage.noListing': 'Aucune annonce',
  'dashboard.chargerPage.chargerStatus.heading': 'État du chargeur',
  'dashboard.chargerPage.chargerStatus.energyConsumption': "Consommation d'énergie (kWh)",
  'dashboard.chargerPage.chargerStatus.revenue': 'Revenus ($)',
  'dashboard.chargerPage.graph.heading': "Revenus, transactions et consommation d'énergie",
  'dashboard.chargerPage.connectorStatus.heading': 'État du connecteur',
  'dashboard.chargerPage.connectorStatus.disconnected': 'Débranché',
  'dashboard.chargerPage.connectorStatus.disconnected.message': 'Environ -1 à -2',
  'dashboard.chargerPage.chargerStatus.offline': 'Hors ligne',
  'dashboard.chargerPage.chargerStatus.online': 'En ligne',
  'dashboard.chargerPage.chargerStatus.available': 'Disponible',
  'dashboard.chargerPage.chargerStatus.charging': 'Mise en charge',
  'dashboard.chargerPage.chargerStatus.commissioning': 'Mise en service',
  'dashboard.chargerPage.chargerStatus.commissioned': 'Commandé',
  'dashboard.chargerPage.chargerStatus.suspendedEV': 'EV suspendu',
  'dashboard.chargerPage.chargerStatus.preparing': 'En cours de préparation',
  'dashboard.chargerPage.chargerStatus.suspendedEVSE': 'EVSE suspendu',
  'dashboard.chargerPage.chargerStatus.faulted': 'En panne',
  'dashboard.chargerPage.chargerStatus.unavailable': 'Indisponible',
  'dashboard.chargerPage.chargerStatus.reserved': 'Réservé',
  'dashboard.chargerPage.chargerStatus.finishing': 'Finition',
  'dashboard.chargerPage.chargerStatus.unknown': 'Inconnue',
  'dashboard.chargerPage.tab.detail': 'Des détails',
  'dashboard.chargerPage.tab.detail.title': 'Titre',
  'dashboard.chargerPage.tab.detail.connectorid': 'Identifiant du connecteur',
  'dashboard.chargerPage.tab.detail.status': 'Statut',
  'dashboard.chargerPage.tab.detail.tenant': 'Site',
  'dashboard.chargerPage.tab.detail.vendor': 'Vendeur',
  'dashboard.chargerPage.tab.detail.firmwareVersion': 'Version du firmware',
  'dashboard.chargerPage.diagnosticsTab.heading': 'Diagnostique',
  'dashboard.chargerPage.diagnostics.files.requestTime.heading': 'Heure de la demande',
  'dashboard.chargerPage.diagnostics.files.state.heading': 'État',
  'dashboard.chargerPage.diagnostics.files.filename.heading': 'Nom de fichier',
  'dashboard.chargerPage.diagnostics.button.text': 'Demander un diagnostic',
  'dashboard.chargerPage.diagnostics.kickButton.text': 'Chargeur de coup de pied',
  'dashboard.chargerPage.remoteControlTab.heading': 'Télécommande',
  'dashboard.chargerPage.remoteControlTab.description': 'Sélectionnez un connecteur pour continuer',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.heading': 'Redémarrer la boîte de charge',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.p1': 'Cliquez ci-dessous pour redémarrer le',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.restartbtn': 'Redémarrage',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.chargerStarted': 'Le chargeur 1234 a été redémarré',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.chargerFailedToStart':
    "Envoi d'une demande de réinitialisation matérielle pour 1234",
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.hardResetRequest':
    "Envoi d'une demande de réinitialisation matérielle pour",
  'dashboard.chargerPage.remoteControlTab.clearCache.heading': 'Vider le cache',
  'dashboard.chargerPage.remoteControlTab.clearCache.title': 'Cliquez ci-dessous pour vider le cache du chargeur',
  'dashboard.chargerPage.remoteControlTab.clearCache.btn.heading': 'Effacer le cache 1234',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.errormessage': 'Sélectionnez un type de message à envoyer',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.message':
    'Envoi du message 0000 au connecteur -1 pour le chargeur 1234',
  'dashboard.chargerPage.remoteControlTab.clearCache.request': "Envoi de la demande d'effacement du cache au",
  'dashboard.chargerPage.remoteControlTab.triggerMessage.heading': 'Message déclencheur',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.text':
    'Sélectionnez un message ci-dessous pour 1234 - connecteur -1 pour initier un message de déclenchement',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.trigger': 'Trigger',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.success':
    'La 1234 a été envoyée à 0000 - connecteur -1 pour le chargeur 1111',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error.message.accepted': 'Accepté',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error.message.rejected': 'Rejeté',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error':
    "La 1234 n'a pas pu être envoyée au connecteur -1 du chargeur 1111",
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error.message': 'Rejeté',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.placeholder': 'Sélectionnez un type de message',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.bootNotification': 'Notification de démarrage',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.diagnosticsStatusNotification':
    "Notification d'état de diagnostic",
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.firmwareStatusNotification':
    "Notification d'état du micrologiciel",
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.heartbeat': 'Battement de coeur',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.meterValue': 'Valeur du compteur',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.statusNotification': "Notification d'état",
  'dashboard.chargerPage.remoteControlTab.triggerMessage.btn.sendTriggerMessage': 'Envoyer un message déclencheur',
  'dashboard.chargerPage.remoteControlTab.updateFirmware.heading': 'Mise à jour du firmware',
  'dashboard.chargerPage.remoteControlTab.changeConfiguration.heading': 'Modifier la configuration',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.heading': 'Obtenir la configuration',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.text':
    'Cliquez ci-dessous pour obtenir la configuration du chargeur',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.getchargerconfiguration': 'Obtenir la configuration de 1234',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.sendchargerconfiguration':
    "Envoi de la demande d'obtention de la configuration au",
  'dashboard.chargerPage.remoteControlTab.getConfiguration.key': 'Clé',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.readonly': 'Lecture seulement',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.value': 'Valeur',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.table.callresult': "Résultat de l'appel",
  'dashboard.chargerPage.remoteControlTab.getConfiguration.table.callid': "ID d'appel",
  'dashboard.chargerPage.remoteControlTab.getConfiguration.modal.heading': 'Configuration du 1234',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.modal.configurationRetrieved':
    'Configuration du chargeur 1234 récupérée',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.modal.configurationDidNotRetrieve':
    'Impossible de récupérer la configuration du chargeur 1234',
  'dashboard.chargerPage.DREventTab.heading': 'Réponse à la demande',
  'dashboard.chargerPage.logs.chargerLogs.heading': 'Journaux du chargeur',
  'dashboard.chargerPage.logs.timestamp.heading': 'Horodatage',
  'dashboard.chargerPage.logs.heading': 'Enregistrer',
  'dashboard.chargerPage.logs.errorCode.heading': "Code d'erreur",
  'dashboard.chargerPage.logs.vendorErrorCode.heading': "Code d'erreur du fournisseur",
  'dashboard.chargerPage.listing.heading': 'Référencement',
  'dashboard.chargerPage.listing.listingTitle.heading': "Titre de l'annonce",
  'dashboard.chargerPage.listing.connectorid.heading': 'Identifiant du connecteur',
  'dashboard.chargerPage.listing.price.heading': 'Prix',
  'dashboard.chargerPage.listing.pricetype.heading': 'Type de prix',
  'dashboard.chargerPage.listing.action.heading': 'Action',
  'dashboard.chargerPage.listing.action.modifybtn': 'Modifier',
  'dashboard.chargerPage.listing.action.saveBtn': 'Sauvegarder',
  'dashboard.chargerPage.listing.action.cancelBtn': 'Annuler',

  /*************************** Transactions Page ***************************/
  'dashboard.transactionsPage.heading': 'Transactions',
  'dashboard.transactionsPage.exportbtn': 'Exportation',
  'dashboard.transactionsPage.advancedSearch': 'Recherche Avancée',
  'dashboard.transactionsPage.advancedSearchHide': 'Masquer la Recherche Avancée',
  'dashboard.transactionsPage.advancedSearch.transactionId': 'identifiant de transaction',
  'dashboard.transactionsPage.advancedSearch.username': "Nom d'utilisateur",
  'dashboard.transactionsPage.advancedSearch.useremail': "Courriel de l'utilisateur",
  'dashboard.transactionsPage.advancedSearch.useremail.warning': "L'entrée n'est pas valide E-mail!",
  'dashboard.transactionsPage.advancedSearch.ocppTransactionId': 'ID de transaction OCPP',
  'dashboard.transactionsPage.advancedSearch.startMethod': 'Méthode de démarrage',
  'dashboard.transactionsPage.advancedSearch.state': 'État',
  'dashboard.transactionsPage.advancedSearch.state.cancelled': 'annulé',
  'dashboard.transactionsPage.advancedSearch.state.confirmed': 'confirmé',
  'dashboard.transactionsPage.advancedSearch.state.errored': 'erroné',
  'dashboard.transactionsPage.advancedSearch.state.free': 'Gratuit',
  'dashboard.transactionsPage.advancedSearch.state.no_reservation': 'pas de réservation',
  'dashboard.transactionsPage.advancedSearch.state.paid': 'payé',
  'dashboard.transactionsPage.advancedSearch.state.preauthorized': 'préautorisé',
  'dashboard.transactionsPage.advancedSearch.state.checked_in': 'enregistré',
  'dashboard.transactionsPage.advancedSearch.state.pending_payment': 'En attente de paiement',
  'dashboard.transactionsPage.advancedSearch.startMethod.rfid': 'rfid',
  'dashboard.transactionsPage.advancedSearch.startMethod.mobile': 'mobile',
  'dashboard.transactionsPage.advancedSearch.startMethod.website': 'site Internet',
  'dashboard.transactionsPage.advancedSearch.startMethod.location': 'Lieu',
  'dashboard.transactionsPage.totalenergy': 'Énergie totale (kWh)',
  'dashboard.transactionsPage.totalnetenergy': 'Revenu net total',
  'dashboard.transactionsPage.totalChargingDuration': 'Durée totale de charge',
  'dashboard.transactionsPage.table.transactionId': 'Identifiant',
  'dashboard.transactionsPage.table.startMethod': 'Méthode de démarrage',
  'dashboard.transactionsPage.table.user': 'Utilisateur',
  'dashboard.transactionsPage.table.userName': "Nom d'utilisateur",
  'dashboard.transactionsPage.table.state': 'État',
  'dashboard.transactionsPage.table.state.errored': 'Erreur',
  'dashboard.transactionsPage.table.state.pendingpayment': 'En attente de paiement',
  'dashboard.transactionsPage.table.state.free': 'Libérer',
  'dashboard.transactionsPage.table.state.confirmed': 'Confirmé',
  'dashboard.transactionsPage.table.state.cancelled': 'Annulé',
  'dashboard.transactionsPage.table.state.no_reservation': 'pas de réservation',
  'dashboard.transactionsPage.table.state.paid': 'Payé',
  'dashboard.transactionsPage.table.state.preauthorized': 'préautorisé',
  'dashboard.transactionsPage.table.state.checkedin': 'Enregistré',
  'dashboard.transactionsPage.table.duration': 'Durée',
  'dashboard.transactionsPage.table.connectedAt': 'Connecté à',
  'dashboard.transactionsPage.table.connected': 'Connecté',
  'dashboard.transactionsPage.table.disconnected': 'Débranché',
  'dashboard.transactionsPage.table.disconnectedAt': 'Déconnecté à',
  'dashboard.transactionsPage.table.plugInDuration': 'Durée du plug-in',
  'dashboard.transactionsPage.table.energy': 'Énergie (kWh)',
  'dashboard.transactionsPage.table.total': 'Le total',
  'dashboard.transactionsPage.table.totalEnergy': 'Énergie totale',
  'dashboard.transactionsPage.table.action': 'Action',
  'dashboard.transactionsPage.table.action.error': 'Erreur',
  'dashboard.transactionsPage.table.nestedTable.heading.connectorId': 'Identifiant du connecteur',
  'dashboard.transactionsPage.table.nestedTable.heading.battery': 'Pourcentage de batterie (%)',
  'dashboard.transactionsPage.table.nestedTable.heading.chargedAt': 'Facturé à',
  'dashboard.transactionsPage.table.nestedTable.heading.endChargedAt': 'Fin de charge à',
  'dashboard.transactionsPage.table.nestedTable.heading.chargingDuration': 'Durée de charge',
  'dashboard.transactionsPage.table.nestedTable.heading.subtotal': 'Sous-total',
  'dashboard.transactionsPage.table.nestedTable.heading.tax': 'Impôt',
  'dashboard.transactionsPage.table.nestedTable.heading.seller': 'Vendeur',
  'dashboard.transactionsPage.table.nestedTable.heading.chargingStats': 'Statistiques de charge',
  'dashboard.transactionsPage.chargingStart': 'Démarrage de la charge',
  'dashboard.transactionsPage.chargingEnd': 'Fin de la charge',
  'dashboard.transactionsPage.chargingDuration': 'Durée de charge',
  'dashboard.transactionsPage.statsView.totalTransactions': 'Transactions totales',
  'dashboard.transactionsPage.statsView.totalEnergyWithKwh': 'Énergie totale (kWh)',
  'dashboard.transactionsPage.statsView.totalEnergy': 'Énergie totale',
  'dashboard.transactionsPage.statsView.totalNetRevenue': 'Revenu net total',
  'dashboard.transactionsPage.export.title': 'Exportation des données',
  'dashboard.transactionsPage.export.message':
    "Choisissez d'exporter les données via PDF ou CSV. Le fichier sera envoyé par courriel à",

  /*************************** Transaction Page ***************************/
  'dashboard.transactionPage.transactionId': 'identifiant de transaction',
  'dashboard.transactionPage.tenant': 'Site',
  'dashboard.transactionPage.startMethod': 'Méthode de démarrage',
  'dashboard.transactionPage.user': 'Utilisateur',
  'dashboard.transactionPage.batteryLevel': 'Niveau de batterie (%)',
  'dashboard.transactionPage.connectedAt': 'Connecté à',
  'dashboard.transactionPage.disconnectedAt': 'Déconnecté à',
  'dashboard.transactionPage.pluginDuraction': 'Durée du plug-in',
  'dashboard.transactionPage.energy': 'Énergie (kWh)',
  'dashboard.transactionPage.total': 'Le total',
  'dashboard.transactionPage.stop': 'Arrêter',
  'dashboard.transactionPage.start': 'Démarrer',
  'dashboard.transactionPage.error': 'Erreur',
  'dashboard.transactionPage.action': 'action',
  'dashboard.transactionPage.listing': 'Référencement',
  'dashboard.transactionPage.connectorId': 'Identifiant du connecteur',
  'dashboard.transactionPage.chargedAt': 'Facturé à',
  'dashboard.transactionPage.endChargingAt': 'Fin de charge à',
  'dashboard.transactionPage.chargingDuration': 'Durée de charge',
  'dashboard.transactionPage.subtotal': 'Sous-Total',
  'dashboard.transactionPage.tax': 'Impôt',
  'dashboard.transactionPage.seller': 'Vendeur',
  'dashboard.transactionsPage.statsView.cancel': 'Annuler',
  'dashboard.transactionsPage.statsView.error': 'Erreur',
  'dashboard.transactionsPage.statsView.total': 'Le total',

  /*************************** Transaction State Badge  ***************************/
  'dashboard.transaction.confirmed': 'Confirmé',
  'dashboard.transaction.free': 'Libérer',
  'dashboard.transaction.pending_payment': 'En attente de paiement',
  'dashboard.transaction.canceled': 'Annulé',
  'dashboard.transaction.errored': 'Erreur',
  'dashboard.transaction.paid': 'Payé',
  'dashboard.transaction.preauthorized': 'Pré autorisé',
  'dashboard.transaction.noReservation': 'Pas de réservation',
  'dashboard.transaction.ongoing': 'En cours',
  'dashboard.transaction.completed': 'Complété',
  'dashboard.transaction.reserved': 'Réservé',

  /*************************** Tenants Page ***************************/
  'dashboard.tenantsPage.heading': 'Sites',
  'dashboard.tenantsPage.createNewTenantBtn': 'Créer un nouveau site',
  'dashboard.tenantsPage.searchTenantTxt': 'Rechercher un site',
  'dashboard.tenantsPage.table.heading.name': 'Nom',
  'dashboard.tenantsPage.table.heading.chargerCount': 'Nombre de chargeurs',

  /******************* Individual Tenant Page ***************************/
  'dashboard.tenantPage.detailTab.heading': 'Détail',
  'dashboard.tenantPage.overviewTab.uniformServiceStatuses': 'Statuts de service uniformes',
  'dashboard.tenantPage.overviewTab.heading': 'Aperçu',
  'dashboard.tenantPage.overviewTab.channelPartner': 'Partenaire de canal',
  'dashboard.tenantPage.overviewTab.editTenant': 'Modifier le statut du site',
  'dashboard.tenantPage.chargersTab.heading': 'Chargeurs',
  'dashboard.tenantPage.detailTab.accessCode.warning': "Veuillez entrer un code d'accès à 6 chiffres",
  'dashboard.tenantPage.detailTab.ocppName.heading': 'Nom OCPP',
  'dashboard.tenantPage.detailTab.ocppName.warning': 'Veuillez saisir un nom OCPP',
  'dashboard.tenantPage.detailTab.address.warning': "entrez une adresse s'il vous plaît",
  'dashboard.tenantPage.detailTab.city.warning': 'Veuillez entrer la ville',
  'dashboard.tenantPage.detailTab.displayName.heading': 'Afficher un nom',
  'dashboard.tenantPage.detailTab.address.heading': 'Adresse',
  'dashboard.tenantPage.detailTab.city.heading': 'Ville',
  'dashboard.tenantPage.detailTab.postalCode.heading': 'code postal',
  'dashboard.tenantPage.detailTab.postalCode.warning':
    "C'est un peu long non ? Veuillez vous limiter à 10 caractères ici.",
  'dashboard.tenantPage.detailTab.countryAndProvince.heading': 'Pays et province',
  'dashboard.tenantPage.detailTab.timezone': 'Fuseau horaire',
  'dashboard.tenantPage.detailTab.location': 'Emplacement',
  'dashboard.tenantPage.detailTab.open247': 'Ouvert 24h/24 et 7j/7',
  'dashboard.tenantPage.detailTab.ocpiSharing': 'Partage OCPI',
  'dashboard.tenantPage.detailTab.saveBtn': 'Sauvegarder',
  'dashboard.tenantPage.detailTab.resetbtn': 'Réinitialiser',
  'dashboard.tenantPage.detailTab.ocppNameAlreadyExists.warning': 'Le nom OCPP existe déjà',
  'dashboard.tenantPage.detailTab.displayNameAlreadyExists.warning': "Le nom d'affichage existe déjà",
  'dashboard.tenantPage.postalCode.19103.info': "Un taux de taxe de 8 % s'appliquera aux chargeurs de ce code postal",
  'dashboard.tenantPage.overviewTab.heading.accessPolicy': "Politique d'accès",
  'dashboard.tenantPage.overviewTab.heading.billingPlan': 'Plan de facturation',
  'dashboard.tenantPage.contactInfo.ownerContact': 'Contact du propriétaire',
  'dashboard.tenantPage.contactInfo.ownerContact.info':
    "Si le chargeur est la propriété privée d'un résident en copropriété ou en copropriété, indiquez ici les coordonnées du propriétaire",
  'dashboard.tenantPage.contactInfo.accountContact': 'Coordonnées du compte',
  'dashboard.tenantPage.contactInfo.onsiteContact': 'Contact sur place',
  'dashboard.tenantPage.contactInfo.installerContact': "Coordonnées de l'installateur",
  'dashboard.tenantPage.contactInfo.additionalContacts': 'Contacts supplémentaires',
  'dashboard.tenantPage.contactInfo.add-additionalContacts': 'Ajouter des contacts supplémentaires',
  'dashboard.tenantPage.contactInfo.location.err': "Veuillez mettre à jour l'emplacement des locataires",
  'dashboard.tenantPage.contactInfo.firstName': 'Prénom',
  'dashboard.tenantPage.contactInfo.lastName': 'Nom de famille',
  'dashboard.tenantPage.contactInfo.title': "Titre d'emploi",
  'dashboard.tenantPage.contactInfo.modal.title': 'Ajouter un contact supplémentaire',
  'dashboard.tenantPage.contactInfo.modal.addbtn': 'Ajouter',
  'dashboard.tenantPage.contactInfo.phoneNumber': 'Numéro de téléphone',
  'dashboard.tenantPage.contactInfo.email': 'E-mail',
  'dashboard.tenantPage.contactInfo.typeofcontact': 'Type de contact',
  'dashboard.tenantPage.contactInfo.validemail.warning': 'Entrez une adresse email valide!',
  'dashboard.tenantPage.contactInfo.typeofcontactalreadyexixts.warning': 'Le type de contact existe déjà',
  'dashboard.tenantPage.contactInfo.typeofcontact.warning': 'Veuillez entrer le type de contact',
  'dashboard.tenantPage.contactInfo.propertymanagementcompany': 'Société de gestion immobilière',
  'dashboard.tenantPage.contactInfo.contactUpdated': 'Contact mis à jour',
  'dashboard.tenantPage.contactInfo.contactCreated': 'Contact créé',
  'dashboard.tenantPage.deployment.parkingstalls.heading': 'Stationnement',
  'dashboard.tenantPage.deployment.parkingstall.heading': 'Stationnement',
  'dashboard.tenantPage.deployment.loadmanagementconfiguration.heading': 'Configuration de la gestion de la charge',
  'dashboard.tenantPage.deployment.remotedesktopids.heading': 'ID de bureau à distance',
  'dashboard.tenantPage.deployment.electricalservice.heading': 'Service électrique',
  'dashboard.tenantPage.deployment.networkdevice.heading': 'Périphérique réseau',
  'dashboard.tenantPage.deployment.notes.heading': 'Remarques',
  'dashboard.tenantPage.deployment.btn.save': 'Sauver',
  'dashboard.tenantPage.deployment.btn.modify': 'Modifier',
  'dashboard.tenantPage.deployment.btn.addfield': 'Ajouter le champ',
  'dashboard.tenantPage.deployment.btn.cancel': 'Annuler',
  'dashboard.tenantPage.deployment.successmsg': 'Informations de déploiement mises à jour',
  'dashboard.tenantPage.deployment.modal.addnewparkingstall.heading':
    'Ajouter un nouveau champ de place de stationnement',
  'dashboard.tenantPage.deployment.modal.loadmanagementconfiguration.heading':
    'Ajouter un nouveau champ de configuration de gestion de la charge',
  'dashboard.tenantPage.deployment.modal.remotedesktopids.heading': 'Ajouter un nouvel ID de bureau à distance',
  'dashboard.tenantPage.deployment.field.warning': 'Le champ ne peut pas être vide !',
  'dashboard.tenantPage.overviewTab.heading.userType': "Type d'utilisateur",
  'dashboard.tenantPage.overviewTab.heading.networkinformation': 'Informations sur le réseau',
  'dashboard.tenantPage.overviewTab.heading.circuitSharingInformation': 'Informations sur le partage de circuits',
  'dashboard.tenantPage.overviewTab.saveBtn': 'Sauvegarder',
  'dashboard.tenantPage.overviewTab.modifybtn': 'Modifier',
  'dashboard.tenantPage.overviewTab.cancelBtn': 'Annuler',
  'dashboard.tenantPage.overviewTab.tenantUpdatedMessage': 'le -1 a été mis à jour !',
  'dashboard.tenantPage.overviewTab.status.heading': 'Statut',
  'dashboard.tenantPage.overviewTab.status.inprogress': 'En cours',
  'dashboard.tenantPage.overviewTab.status.commissioned': 'Commissionné',
  'dashboard.tenantPage.overviewTab.status.active': 'Actif',
  'dashboard.tenantPage.overviewTab.status.inmaintenance': 'En maintenance',
  'dashboard.tenantPage.overviewTab.status.nonNetworked': 'Non en réseau',
  'dashboard.tenantPage.overviewTab.status.testerInternal': 'Testeur – Interne',
  'dashboard.tenantPage.overviewTab.status.testerExternal': 'Testeur – Externe',
  'dashboard.tenantPage.overviewTab.status.decommissioned': 'Désaffecté',
  'dashboard.tenantPage.overviewTab.o_m_opt_in.heading': 'Opt-in O&M',
  'dashboard.tenantPage.overviewTab.o_m_opt_in.yes': 'Oui',
  'dashboard.tenantPage.overviewTab.o_m_opt_in.no': 'Non',
  'dashboard.tenantPage.overviewTab.chargerServiceStatus': 'État du service du chargeur',
  'dashboard.tenantPage.overviewTab.chargerServiceStatus.help':
    "IMPORTANT : La sélection de l'une des valeurs mettra à jour l'état de service de tous les chargeurs associés à ce site",
  'dashboard.tenantPage.overviewTab.connectorServiceStatus': 'État du service du connecteur',
  'dashboard.tenantPage.overviewTab.status.help':
    'REMARQUE : Le site ne peut pas être supprimé si une ou plusieurs annonces sont toujours jointes!',
  'dashboard.tenantPage.locationTab.uniformupdate.help':
    'IMPORTANT : Si vous cochez cette case, toutes les annonces associées à ce site seront masquées sur la carte.',
  'dashboard.tenantPage.location.heading': 'Lieu',
  'dashboard.tenantPage.accesscontrolsetting.heading': "Paramètre de contrôle d'accès",
  'dashboard.tenantPage.accesscontrolsetting.restricted': 'Limité',
  'dashboard.tenantPage.accesscontrolsetting.openaccess': 'Libre accès',
  'dashboard.tenantPage.accesscode.heading': "Code d'accès",
  'dashboard.tenantPage.accesspolicy.heading': "Politique d'accès",
  'dashboard.tenantPage.accesscode.option.public': 'Public',
  'dashboard.tenantPage.accesscode.option.privateindividual': 'Privé individuel',
  'dashboard.tenantPage.accesscode.option.privateshared': 'Privé Partagé',
  'dashboard.tenantPage.notes': 'Remarques',
  'dashboard.tenantPage.notes.warning': 'La limite est de 65 535 caractères',
  'dashboard.tenantPage.heading.pricing': 'Tarification',
  'dashboard.tenantPage.heading.pricingSchema': 'Schéma de tarification',
  'dashboard.tenantPage.heading.contactInfo': 'Informations de contact',
  'dashboard.tenantPage.heading.loadManagement': 'Gestion de la charge',
  'dashboard.tenantPage.heading.deployment': 'Déploiement',
  'dashboard.tenantPage.chargersTab.listings': 'Annonces',
  'dashboard.tenantPage.chargersTab.tenantupdated': 'a été mis à jour!',
  'dashboard.tenantPage.chargersTab.utilizationRate': "Taux d'utilisation",
  'dashboard.tenantPage.chargersTab.utilizationRate.description':
    "Le taux d'utilisation est calculé en fonction du temps pendant lequel le chargeur est connecté à un véhicule, que le véhicule soit en cours de charge ou non (c'est-à-dire Statut OCPP du chargeur : préparation, charge, finition, suspension EV).",
  'dashboard.tenantPage.chargersTab.uptimePercentage': 'Pourcentage de disponibilité',
  'dashboard.tenantPage.chargersTab.uptimePercentage.description':
    "Le pourcentage de disponibilité mesure le temps pendant lequel le chargeur est opérationnel, soit en ligne, disponible pour utilisation, ou en train de charger activement un véhicule. Ce pourcentage exclut les temps d'arrêt causés par des facteurs hors du contrôle de SWTCH, tels que le vandalisme, les pannes généralisées en ville ou les catastrophes naturelles.",
  'dashboard.tenantPage.eyedroIpAddress.heading': 'Service du bâtiment - Charge du panneau EV',
  'dashboard.tenantPage.eyedroIpAddress.powerfactor': 'Facteur de puissance',
  'dashboard.tenantPage.eyedroIpAddress.voltage': 'Tension (V)',
  'dashboard.tenantPage.eyedroIpAddress.amperage': 'Ampérage (A)',
  'dashboard.tenantPage.eyedroIpAddress.wattage': 'Puissance (W)',
  'dashboard.tenantPage.eyedroIpAddress.ipaddress': 'Adresse IP',
  'dashboard.tenantPage.error.geocodingError': "Le résultat du géocodage est à l'extérieur de la province",
  'dashboard.tenantPage.error.coordinateError': 'Impossible de trouver les coordonnées de cette adresse',
  'dashboard.tenantPage.error.countryAndProvinceError': 'Veuillez choisir un pays et une province pour ce locataire',
  'dashboard.tenantPage.error.googleLatLongError':
    'Google ne nous a pas donné de latitude et de longitude pour cette adresse',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.checkbox.text': 'Tarification uniforme',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.enabled':
    'Tarification uniforme activée pour toutes les annonces du locataire',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.disabled':
    'Tarification uniforme désactivée pour toutes les annonces du locataire',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.updated': 'tarification uniforme mise à jour !',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.info.p1':
    "S'il est activé, il indique que tous les chargeurs de ce locataire auront la même configuration de tarification.",
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.info.p2':
    'Si désactivé, les paramètres de tarification de chaque chargeur doivent être configurés individuellement',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.listing': "Auteur de l'annonce",
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.stripeAccount.text': 'Compte Stripe',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectListingAuthorText': "Sélectionnez l'auteur de la liste",
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectStripeAccountText': 'Sélectionnez un compte Stripe',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.priceCentsText': 'Prix ​​($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.kwOutputBasedPricingText':
    'Tarification horaire basée sur le kWh',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.enableTenantPreferredPricingText':
    'Activer la tarification préférentielle du locataire',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.tenantPreferredPricingText':
    'Tarification préférentielle pour les sites ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.tenantPreferredWarningText':
    'Le prix préféré ne peut pas être vide !',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.tenantPreferredPriceTypeWarningText':
    'Veuillez sélectionner un type pour le prix préféré',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.priceText': 'Prix',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.priceTypeHourText': 'Heure',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.flatFeeText': 'Frais fixes ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionStructureText':
    'Définir une nouvelle structure de commission',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionTypeText':
    'Définir un nouveau type de frais de commission',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionFeeText':
    'Définir les nouveaux frais de commission (%)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.companyCommissionFee': `Commission de ${currentSiteOwner} (%)`,
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.partner': 'Partage des revenus avec le partenaire',
  'dashboard.text.partnerSharePercentage': 'Part de partenaire (%)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionTypeWarningText':
    'Les frais de commission ne peuvent pas être vides !',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.guestFeeText': "Frais d'invité ($)",
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.paymentLimitText': 'Limite de paiement ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText': 'Statut de vagabondage',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.none': 'Aucun',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.statusBased': 'Basé sur le statut',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.timeBased': 'Basé sur le temps',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimitText':
    'Limite de temps de flânerie basée sur le temps en minutes',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringPenaltyPerHour':
    'Pénalité de vagabondage par heure ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimit.emptyfield':
    'Le champ ne peut pas être vide !',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimitText.gracetimelimit.minutes':
    'Limite de temps de flânerie pour la période de grâce en minutes',
  'dashboard.tenantPage.uniformPricingTab.updatedtext': 'prix uniforme mis à jour !',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectComissionFeeText':
    'Sélectionnez les frais de commission',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectPreferredPricingOptionText':
    "Sélectionnez l'option de tarification préférée",
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectPricingOptionText':
    "Sélectionnez l'option de tarification",
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.saveBtn': 'Sauvegarder',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.modifybtn': 'Modifier',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.cancelBtn': 'Annuler',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.toggleUniformPricingText': 'Basculer la tarification uniforme',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.uniformPricing.text': 'Tarification uniforme',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.uniformPricing..enable.text': 'Activer',
  'dashboard.tenantPage.accessCodeuniformUpdateText': "Contrôle d'accès uniforme",
  'dashboard.tenantPage.mapSettingsuniformUpdateText': 'Paramètres de carte uniformes',
  'dashboard.tenantPage.locationTab.hideTenantFromListingsText': 'Annonces cachées sur la carte',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.heading':
    "Activer la tarification selon l'heure de la journée",
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.weekdays': 'Jours de la semaine',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.weekends': 'Fins de semaine',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.start': 'Commencer',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.end': 'Fin',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.enablePricingForTenantMembers':
    'Activer la tarification pour les membres sites',
  'dashboard.tenantPage.enableLoitering': 'Désactiver le vagabondage pour le membre site',
  'dashboard.tenantPage.overviewTab.taxRegistration.heading': 'Enregistrement fiscal',
  'dashboard.tenantPage.overviewTab.taxRegistration.option.registered': 'Enregistré',
  'dashboard.tenantPage.overviewTab.taxRegistration.option.not_registered': 'Non enregistré',
  'dashboard.tenantPage.overviewTab.taxRegistration.option.joint_elected': 'Élu conjoint',
  'dashboard.tenantPage.overviewTab.remittanceEmail': 'Email(s) de remise',
  'dashboard.tenantPage.overviewTab.remittanceFrequency': 'Fréquence de remise',
  'dashboard.tenantPage.overviewTab.editAccountInfo': 'Modifier les informations du compte',
  'dashboard.tenantPage.overviewTab.editAccountDetails': 'Modifier les détails du compte',
  'dashboard.tenantPage.overviewTab.commaDelimited': 'Délimité par des virgules',
  'dashboard.tenantPage.overviewTab.monthly': 'Mensuel',
  'dashboard.tenantPage.overviewTab.quarterly': 'Trimestriel',
  'dashboard.tenantPage.overviewTab.annually': 'Annuellement',
  'dashboard.tenantPage.overviewTab.caas': 'CaaS',
  'dashboard.tenantPage.overviewTab.swtchCare': 'Soins SWTCH',
  'dashboard.tenantPage.overviewTab.arenaOrStadium': 'Arène ou Stade',
  'dashboard.tenantPage.overviewTab.campground': 'Camping',
  'dashboard.tenantPage.overviewTab.fleet': 'Flotte',
  'dashboard.tenantPage.overviewTab.gasStation': 'Station-essence',
  'dashboard.tenantPage.overviewTab.government': 'Gouvernement',
  'dashboard.tenantPage.overviewTab.hospital': 'Hôpital',
  'dashboard.tenantPage.overviewTab.hotel': 'Hôtel',
  'dashboard.tenantPage.overviewTab.multiUnitResidential': 'Résidentiel multi-unités',
  'dashboard.tenantPage.overviewTab.onStreet': 'Dans la rue',
  'dashboard.tenantPage.overviewTab.parkingLot': 'Terrain de stationnement',
  'dashboard.tenantPage.overviewTab.parkingGarage': 'Parking Garage',
  'dashboard.tenantPage.overviewTab.public': 'Publique',
  'dashboard.tenantPage.overviewTab.retail': 'Vente au détail',
  'dashboard.tenantPage.overviewTab.schoolUniversity': 'École/Université',
  'dashboard.tenantPage.overviewTab.singleFamilyResidential': 'Résidentiel unifamilial',
  'dashboard.tenantPage.overviewTab.workplace': 'Lieu de travail',
  'dashboard.tenantPage.pricingSchemaTab.time': 'Temps',
  'dashboard.tenantPage.pricingSchemaTab.nameInUse': 'Nom déjà utilisé',
  'dashboard.tenantPage.pricingSchemaTab.hoursNotDefined':
    'Toutes les heures non définies suivront le tarif par défaut.',
  'dashboard.tenantPage.pricingSchemaTab.to': 'À',
  'dashboard.tenantPage.pricingSchemaTab.constants': 'Constantes',
  'dashboard.tenantPage.pricingSchemaTab.defineNewCommissionFee': 'Définir de nouveaux frais de commission',
  'dashboard.tenantPage.pricingSchemaTab.rate': 'Taux',
  'dashboard.tenantPage.pricingSchemaTab.curtailmentAdjustment': 'Ajustement des réductions',
  'dashboard.tenantPage.pricingSchemaTab.timeOfDay': 'Moment de la journée',
  'dashboard.tenantPage.pricingSchemaTab.weekdayRate': 'Tarif en semaine',
  'dashboard.tenantPage.pricingSchemaTab.weekendRate': 'Tarif week-end',
  'dashboard.tenantPage.pricingSchemaTab.loiteringRate': 'Taux de flânerie',
  'dashboard.tenantPage.pricingSchemaTab.gracePeriod': 'Délai de grâce (min)',
  'dashboard.tenantPage.pricingSchemaTab.timeLimit': 'Limite de temps (min)',
  'dashboard.tenantPage.pricingSchemaTab.timeOfDayLoitering': 'Moment de la journée',
  'dashboard.tenantPage.pricingSchemaTab.weekdayLoiteringRate': 'Taux de flânerie en semaine',
  'dashboard.tenantPage.pricingSchemaTab.weekendLoiteringRate': 'Tarif pour flânerie le week-end',
  'dashboard.tenantPage.pricingSchemaTab.paymentProcessingFee': 'Frais de traitement des paiements',
  'dashboard.tenantPage.pricingSchemaTab.commissionFee': 'Frais de commission',
  'dashboard.tenantPage.pricingSchemaTab.newCommissionFee': 'Nouveaux frais de commission',
  'dashboard.tenantPage.pricingSchemaTab.curtailmentAdjustmentEnabled':
    'Tarification d’ajustement de réduction activée',
  'dashboard.tenantPage.pricingSchemaTab.maxLoitering': 'Max flânant',
  'dashboard.tenantPage.pricingSchemaTab.commissionPercent': 'Pourcentage de commission',
  'dashboard.tenantPage.pricingSchemaTab.discountPlan': 'Plan de réduction',
  'dashboard.tenantPage.pricingSchemaTab.pricingPlan': 'Plan tarifairen',
  'dashboard.tenantPage.pricingSchemaTab.pricingPlan.updated': 'Plan tarifaire mis à jour avec succès',
  'dashboard.tenantPage.pricingSchemaTab.pricingPlan.created': 'Schéma tarifaire créé avec succès',
  'dashboard.tenantPage.pricingSchemaTab.pricingPlan.selectedListings': 'Chargeur(s) sélectionné(s) :',
  'dashboard.tenantPage.tenantTabOverview.tenantStatus': 'Statut du site',
  'dashboard.tenantPage.tenantTabOverview.editStatus': 'Statut de modification',
  'dashboard.tenantPage.tenantTabOverview.accountName': 'Nom du compte',
  'dashboard.text.accountNames': 'Nom(s) de compte',
  'dashboard.tenantPage.partnerIntegrationTab.heading': 'Intégration de Plateforme',
  'dashboard.tenantPage.partnerIntegrationTab.partner': 'Plateforme',
  'dashboard.tenantPage.pricingSchemaTab.curtailmentAdjustmentInfoText':
    "S'il est activé, le taux horaire sera calculé au prorata en fonction de la production",
  'dashboard.tenantPage.pricingSchemaTab.selectedListingsInfoText':
    'Les annonces sélectionnées seront mises à jour avec le nouveau plan tarifaire',
  'dashboard.tenantPage.pricingSchemaTab.maxLoiteringInfoText':
    'Il s’agit du montant maximum facturé aux conducteurs pour flânage.',
  'dashboard.tenantPage.pricingSchemaTab.paymentLimitText': 'Limite de paiement',
  'dashboard.text.initialActivationDate': 'Date d’activation initiale',
  'dashboard.tenantPage.overviewTab.chargerSettings': 'Paramètres du chargeur',
  'dashboard.tenantPage.overviewTab.chargerSettingsSelected': 'Paramètres du chargeur sélectionnés',
  'dashboard.text.propertyManagementSoftwareCode': 'Code du logiciel de gestion de la propriété',
  'dashboard.text.platformSuccess': 'Plateforme mise à jour avec succès',
  'dashboard.text.selectPartner': 'Sélectionnez un partenaire',
  'dashboard.text.platformCode': 'Code de la plateforme',
  'dashboard.tenantPage.pricingSchemaTab.canadaKwhInfo':
    'Pour établir un tarif en kWh, vous devez faire une demande via le programme de Dispensation Temporaire de Mesures Canada. Vous pouvez consulter <link1>les conditions générales ici<link1>. Si vous êtes éligible, faites une demande de dispense directement sur le <link2>site web de Mesures Canada ici<link2>.',

  /******************* Edit Tenant Page ***************************/
  'dashboard.tenantPage.editTenant.inActiveDescription':
    'Veuillez noter que le changement du statut du locataire à Inactif entraînera également la mise à jour des éléments suivants :',
  'dashboard.tenantPage.editTenant.inActiveOption1Left': 'Statuts des services de listings',
  'dashboard.tenantPage.editTenant.inActiveOption1Right': 'Désactivé',
  'dashboard.tenantPage.editTenant.inActiveOption2Left': 'Accès aux listings',
  'dashboard.tenantPage.editTenant.inActiveOption2Right': 'Restreint',
  'dashboard.tenantPage.editTenant.inActiveOption3Left': 'Listings masqués sur la carte',
  'dashboard.tenantPage.editTenant.inActiveOption3Right': 'Vrai',
  'dashboard.tenantPage.editTenant.inActiveOption4Left': 'Partage OCPI',
  'dashboard.tenantPage.editTenant.inActiveOption4Right': 'Faux',

  'dashboard.tenantPage.editTenant.deletedDescription':
    'Veuillez noter que le changement du statut du locataire à Supprimé entraînera également la mise à jour des éléments suivants :',
  'dashboard.tenantPage.editTenant.deletedDescriptionOption1Left': 'Partage OCPI',
  'dashboard.tenantPage.editTenant.deletedDescriptionOption1Right': 'Faux',

  /******************* Create Tenant Page ***************************/
  'dashboard.createnewtenantspage.heading': 'Créer un nouveau site',
  'dashboard.tenantPage.chargersTab.tenantcreated': 'a été créé avec succès!',

  /******************* Analytics Page *********************************/
  'dashboard.analyticsPage.heading': 'Résumé de déconnexion',
  'dashboard.analyticsPage.searchtenant': 'Rechercher un site',
  'dashboard.analyticsPage.searchBtn': 'Chercher',
  'dashboard.analyticsPage.table.heading.avgDisconnectDuration': 'Locataire',
  'dashboard.analyticsPage.table.heading.chargerSerialNumber': 'Numéro de série du chargeur',
  'dashboard.analyticsPage.table.heading.date': 'Date',
  'dashboard.analyticsPage.table.heading.avgDailyDisconnect': 'Déconnexion quotidienne moyenne',
  'dashboard.analyticsPage.table.heading.totalDisconnect': 'Déconnexion totale',
  'dashboard.analyticsPage.table.heading.disconnectHigh': 'Déconnexion élevée',
  'dashboardanalyticsPage.table.heading.avgDisconnectDuration': 'Durée moyenne de déconnexion',

  /******************* Users Page *********************************/
  'dashboard.usersPage.heading': 'Utilisateurs',
  'dashboard.usersPage.searchUser': 'Rechercher un utilisateur',
  'dashboard.usersPage.authorizeDriver': 'Autoriser le conducteur',
  'dashboard.usersPage.inviteUsersTo': "Inviter l'utilisateur à",
  'dashboard.usersPage.selectatenant': 'Sélectionnez un locataire',
  'dashboard.usersPage.table.heading.id': 'identifiant',
  'dashboard.usersPage.table.heading.email': 'E-mail',
  'dashboard.usersPage.table.heading.name': 'Nom',
  'dashboard.usersPage.table.heading.role': 'Rôle',
  'dashboard.usersPage.table.heading.role.user': 'Utilisateur',
  'dashboard.usersPage.table.heading.role.admin': 'Administrateur',
  'dashboard.usersPage.table.heading.role.support': 'Soutien',
  'dashboard.usersPage.table.heading.tenant': 'Accès site',
  'dashboard.usersPage.table.heading.action': 'Action',
  'dashboard.usersPage.table.heading.access': 'Accès',
  'dashboard.usersPage.table.heading.access.modify': 'Modifier',
  'dashboard.usersPage.table.heading.access.modify.saveBtn': 'Sauvegarder',
  'dashboard.usersPage.table.heading.access.modify.cancelBtn': 'Annuler',
  'dashboard.usersPage.table.heading.access.info': "Accès au tableau de bord de l'utilisateur pour",
  'dashboard.usersPage.table.heading.action.toggleRole': 'Basculer le rôle',
  'dashboard.usersPage.table.nestedTable.associatedTenants': 'Sites associés',
  'dashboard.usersPage.table.nestedTable.accessLevel': "Niveau d'accès",
  'dashboard.usersPage.table.nestedTable.accessLevel.manager': 'Directeur',
  'dashboard.usersPage.table.nestedTable.accessLevel.driver': 'Conducteur',
  'dashboard.usersPage.table.nestedTable.accessLevel.driver.info':
    'Un utilisateur qui a accès à des listes privées dans',
  'dashboard.usersPage.table.nestedTable.accessLevel.viewer': 'Téléspectateur',
  'dashboard.usersPage.table.nestedTable.accessLevel.chargerOperator': 'Opérateur',
  'dashboard.usersPage.invitedUsersTo': 'Utilisateur invité à',
  'dashboard.usersPage.user.tenant.warning.info':
    "Remarque : L'utilisateur n'est pas associé à tous les locataires sélectionnés",
  'dashboard.usersPage.user.tenant.warning.exapandedView': "L'utilisateur n'est pas associé à ce locataire",
  'dashboard.usersPage.emailError': `Veuillez entrer l'adresse e-mail de l'utilisateur qui a déjà créé un compte ${currentSiteOwner}.`,

  /******************* Users Page Invite User Modal Form *************************/
  'dashboard.usersPage.inviteUser.heading': "Inviter l'utilisateur à",
  'dashboard.usersPage.inviteUser.headingInfo': `Invitez les utilisateurs existants de ${currentSiteOwner} à accéder au tableau de bord et autorisez les conducteurs à accéder aux annonces restreintes dans un site.`,
  'dashboard.usersPage.inviteUser.oktext': 'Inviter',
  'dashboard.usersPage.inviteUser.cancelText': 'Annuler',
  'dashboard.usersPage.inviteUser.email.heading': 'E-mail',
  'dashboard.usersPage.inviteUser.firstName.heading': 'Prénom',
  'dashboard.usersPage.inviteUser.lastName.heading': 'Nom de famille',
  'dashboard.usersPage.inviteUser.role.heading': 'Rôle',
  'dashboard.usersPage.inviteUser.email.warning': "L'e-mail est requis!",
  'dashboard.usersPage.inviteUser.email.notValid.warning': "L'e-mail n'est pas un e-mail valide!",
  'dashboard.usersPage.inviteUser.firstName.warning': 'Le prénom est requis!',
  'dashboard.usersPage.inviteUser.lastName.warning': 'Le nom de famille est requis!',
  'dashboard.usersPage.inviteUser.role.warning': 'Le rôle est requis!',
  'dashboard.usersPage.inviteUser.tenant.heading': 'Locataire',
  'dashboard.usersPage.inviteUser.tenant.warning': 'Un locataire doit être sélectionné pour le traitement',
  'dashboard.usersPage.inviteUser.tenants.heading': 'SITE',
  'dashboard.usersPage.inviteUser.discountCode': 'Code de réduction',
  'dashboard.usersPage.inviteUser.addTenants': 'AJOUTER DES SITES',
  'dashboard.usersPage.inviteUser.discountPlan': 'Plan de réduction',
  'dashboard.usersPage.inviteUser.addPermissions': 'AJOUTER DES AUTORISATIONS',

  /******************* Users Page Edit User Name Modal Form *************************/
  'dashboard.usersPage.editUserName.heading': "Modifier le nom de l'utilisateur",
  'dashboard.usersPage.editUserName.firstName.heading': 'Prénom',
  'dashboard.usersPage.editUserName.lastName.heading': 'Nom de famille',
  'dashboard.usersPage.editUserName.cancelText': 'Annuler',
  'dashboard.usersPage.editUserName.updateText': 'Mettre à jour',
  'dashboard.usersPage.editUserName.firstName.warning': 'Le prénom est requis',
  'dashboard.usersPage.editUserName.lastName.warning': 'Le nom de famille est requis',
  'dashboard.usersPage.editUserName.firstName.maxlength.warning': 'Le prénom ne peut pas dépasser 20 caractères!',
  'dashboard.usersPage.editUserName.lastName.maxlength.warning':
    'Le nom de famille ne peut pas dépasser 20 caractères!',
  'dashboard.usersPage.editUser.tenants.heading': 'SITE',

  /******************* Users Page Add User To Multiple Tenants Form *************************/
  'dashboard.usersPage.selectTenants.heading': 'Ajouter un utilisateur à plusieurs locataires',
  'dashboard.usersPage.selectTenants.field': 'Sélectionnez les locataires',
  'dashboard.usersPage.selectTenants.tbl.heading': 'Ajouter un utilisateur aux locataires',
  'dashboard.usersPage.selectTenants.field.warning': 'Un locataire doit être sélectionné!',
  'dashboard.usersPage.selectTenants.addbtn': 'Ajouter',
  'dashboard.usersPage.selectTenants.adduserbtn': 'Ajouter un utilisateur',
  'dashboard.usersPage.selectTenants.successmssg': "L'utilisateur a bien été ajouté aux locataires",
  'dashboard.usersPage.selectTenants.failedmssg': "Impossible d'ajouter l'utilisateur aux locataires",

  /******************* Users Page Authorize Modal Form *************************/
  'dashboard.usersPage.authorizeModal.heading': 'Autoriser les conducteurs à accéder aux listes privées',
  'dashboard.usersPage.authorizeModal.info.heading': 'Autorisation de conducteur',
  'dashboard.usersPage.authorizeModal.info.p1': 'Les conducteurs autorisés auront accès aux listes privées au',
  'dashboard.usersPage.authorizeModal.userSearchField.userText': 'Utilisateur',
  'dashboard.usersPage.authorizeModal.userSearchField.searchUserText': 'Rechercher un utilisateur',
  'dashboard.usersPage.authorizeModal.userSearchField.searchBtn': 'Chercher',
  'dashboard.usersPage.authorizeModal.authorizeBtn': "Autoriser l'utilisateur",
  'dashboard.usersPage.authorizeModal.cancelBtn': 'Annuler',
  'dashboard.usersPage.authorizeModal.warning': "L'utilisateur a déjà accès au locataire sélectionné",
  'dashboard.usersPage.authorizeModal.noMatchFound':
    "Pas de résultat trouvé. Veuillez confirmer que l'utilisateur a crée un compte SWTCH avec l'adresse e-mail indiquée.",

  /******************* Mobile Calendar *********************************/
  'dashboard.mobileCalendar.selectDate': 'Sélectionner une date',
  'dashboard.mobileCalendar.startDate': 'Date de début',
  'dashboard.mobileCalendar.endDate': 'Date de fin',
  'dashboard.mobileCalendar.title': 'Calendrier',
  'dashboard.mobileCalendar.today': "Aujourd'hui",
  'dashboard.mobileCalendar.month': 'Mois',
  'dashboard.mobileCalendar.year': 'An',
  'dashboard.mobileCalendar.am': 'AM',
  'dashboard.mobileCalendar.pm': 'PM',
  'dashboard.mobileCalendar.dateTimeFormat': 'MM/jj/aaaa w hh:mm',
  'dashboard.mobileCalendar.dateFormat': 'aaaa/MM/jj w',
  'dashboard.mobileCalendar.nochoose': 'Non Choisir',
  'dashboard.mobileCalendar.week.sun': 'dimanche',
  'dashboard.mobileCalendar.week.mon': 'lundi',
  'dashboard.mobileCalendar.week.tue': 'mardi',
  'dashboard.mobileCalendar.week.wed': 'mercredi',
  'dashboard.mobileCalendar.week.thu': 'jeudi',
  'dashboard.mobileCalendar.week.fri': 'vendredi',
  'dashboard.mobileCalendar.week.sat': 'samedi',
  'dashboard.mobileCalendar.clear': 'Dégager',
  'dashboard.mobileCalendar.selectTime': "Sélectionnez l'heure",
  'dashboard.mobileCalendar.selectStartTime': "Sélectionnez l'heure de début",
  'dashboard.mobileCalendar.selectEndTime': "Sélectionnez l'heure de fin",
  'dashboard.mobileCalendar.start': 'Démarrer',
  'dashboard.mobileCalendar.end': 'Finir',
  'dashboard.mobileCalendar.begin': 'Démarrer',
  'dashboard.mobileCalendar.over': 'Finir',
  'dashboard.mobileCalendar.begin_over': 'S/E',
  'dashboard.mobileCalendar.confirm': 'Confirmer',
  'dashboard.mobileCalendar.monthTitle': 'aaaa/MM',
  'dashboard.mobileCalendar.loadPrevMonth': 'Charger le mois précédent',
  'dashboard.mobileCalendar.yesterday': 'Hier',
  'dashboard.mobileCalendar.lastWeek': 'La semaine dernière',
  'dashboard.mobileCalendar.lastMonth': 'Le mois dernier',

  /******************* Mobile ListView *********************************/
  'dashboard.mobilelistview.prev': 'Précédent',
  'dashboard.mobilelistview.next': 'Suivant',

  /******************* Disconnection Page *********************************/
  'dashboard.disconnectionpage.heading': 'Résumé de déconnexion',
  'dashboard.disconnectionpage.table.heading.avgDisconnectDuration': 'Locataire',
  'dashboard.disconnectionpage.table.heading.chargerSerialNumber': 'Numéro de série du chargeur',
  'dashboard.disconnectionpage.table.heading.date': 'Date',
  'dashboard.disconnectionpage.table.heading.avgDailyDisconnect': 'Déconnexion quotidienne moyenne',
  'dashboard.disconnectionpage.table.heading.totalDisconnect': 'Déconnexion totale',
  'dashboard.disconnectionpage.table.heading.disconnectHigh': 'Déconnexion élevée',
  'dashboard.disconnectionPage.table.heading.avgDisconnectDuration': 'Durée moyenne de déconnexion',
  'dashboard.disconnectionpage.table.fromHeading': 'À partir de',

  /*************************** Report Page ***************************/
  'dashboard.report.heading': 'Rapports',
  'dashboard.text.reportOverview': 'Aperçu des rapports',
  'dashboard.reports.table.heading.entity': 'Entité',
  'dashboard.reports.table.heading.entityName': 'Nom',
  'dashboard.reports.table.heading.entityType': 'Taper',
  'dashboard.reports.table.heading.dateRange': 'Date',
  'dashboard.reports.table.heading.generator': "L'auteur",
  'dashboard.reports.table.heading.download': 'Télécharger',
  'dashboard.reports.table.heading.email': 'Email',
  'dashboard.reports.table.row.download': 'Télécharger un fichier',
  'dashboard.reports.table.row.timeframe.quarterly': 'Trimestriel',
  'dashboard.reports.table.row.type.transaction': 'Transactions',
  'dashboard.reports.table.row.type.erroredTransaction': 'Transactions erronées',
  'dashboard.reports.table.row.timeframe.yearly': 'Annuel',
  'dashboard.reports.form.sendReport.emails.label': 'Emails (séparées par des virgules)',
  'dashboard.reports.form.sendReport.emails.warning': 'Les e-mails sont obligatoires!',
  'dashboard.reports.form.sendReport.emails.notValid': "L'email n'est pas un e-mail valide!",
  'dashboard.reports.form.sendReport.oktext': 'Rapport par e-mail',
  'dashboard.reports.form.sendReport.generateText': 'Générer',
  'dashboard.reports.form.sendReport.cancelText': 'Annuler',
  'dashboard.reports.form.sendReport.reportFrom': 'rapport de',
  'dashboard.reports.modal.title.generateReport': 'Générer un rapport',
  'dashboard.reports.modal.title.generateErroredReport': 'Générer un rapport erroné',
  'dashboard.reports.modal.generateErroredReport.introText':
    'Cela générera un rapport PDF sur les transactions erronées pour tous les locataires entre',
  'dashboard.reports.modal.generate.introText':
    'Cela générera un rapport PDF du trimestre précédent pour tous les locataires entre',
  'dashboard.reports.modal.generate.endingText': 'Ce processus peut prendre quelques heures une fois lancé.',
  'dashboard.reports.table.text.generator': 'système',
  'dashboard.reports.table.text.reportGeneratedBy': 'Rapport généré par',
  'dashboard.reports.table.text.generatedBy': 'Généré par',
  'dashboard.reports.message.generatingReport': 'Génération de rapports',
  'dashboard.reports.message.generatingErroredTransactionsReport': 'Génération de rapports de transactions erronées',
  'dashboard.reports.modal.title.deleteReport': 'Supprimer le rapport',
  'dashboard.reports.modal.message.deleteReport.text': 'Vous êtes sur le point de supprimer. Êtes-vous sûr?',
  'dashboard.reports.modal.message.deleting': 'Suppression du rapport',
  'dashboard.reports.modal.title.financialSummaryReportTitle': 'Rapport de synthèse financière',
  'dashboard.reports.modal.body.financialSummaryReportBody': `Affiche toutes les transactions de recharge réussies, en détaillant le montant total payé par les conducteurs, les frais collectés par ${configProvider.config.siteTitle} et le montant total à verser à la propriété sur une base trimestrielle.`,
  'dashboard.reports.modal.title.utilityChargeSessionReportTitle':
    'Rapport de session de recharge des services publics',
  'dashboard.reports.modal.body.utilityChargeSessionReportBody':
    "Fournit une vue d'ensemble complète de toutes les transactions de recharge, y compris la consommation d'énergie, les détails du chargeur et les données spécifiques à chaque session",
  'dashboard.reports.modal.title.utilityIntervalReportTitle': "Rapport d'intervalle de service",
  'dashboard.reports.modal.body.utilityIntervalReportBody':
    "Un rapport fréquemment demandé par les programmes utilitaires, proposant des détails de transactions à intervalles de 15 minutes. Comprend des données sur la consommation d'énergie et la puissance maximale/moyenne pour une analyse complète des sessions de charge.",
  'dashboard.text.listingTitle.description': `Souvent appelé ID du chargeur, il s'agit de l'ID unique qui se trouve à l'avant d'un chargeur ${currentSiteOwner}`,
  'dashboard.reports.modal.title.siteReport': 'Rapport de site',
  'dashboard.reports.modal.body.siteReport':
    'Inclut des détails sur la date d’activation du chargeur, le statut opérationnel actuel et précédent, et les détails spécifiques du chargeur.',
  'dashboard.reports.modal.title.chargerUtilizationReport': 'Rapport d’utilisation du chargeur',
  'dashboard.reports.modal.body.chargerUtilizationReport':
    'Affiche l’utilisation totale de chaque borne de recharge sur une période de temps sélectionnée. L’utilisation est calculée en fonction du temps pendant lequel la borne a été branchée par rapport au temps total de la période.',
  'dashboard.reports.modal.title.uptimeReport': 'Rapport de disponibilité',
  'dashboard.reports.modal.body.uptimeReport': `Suit l'état opérationnel des bornes de recharge pour véhicules électriques, en mesurant le temps pendant lequel elles sont actives (c'est-à-dire en ligne, disponibles à l'utilisation ou en train de distribuer activement de l'électricité). Il exclut les temps d'arrêt causés par des événements indépendants de la volonté de ${configProvider.config.siteTitle} afin de fournir une réflexion précise des performances de disponibilité.`,
  'dashboard.text.utilizationPercent.heading': 'Pourcentage d’Utilisation',
  'dashboard.text.utilizationPercent.description':
    'Calculé comme (Somme du Temps d’Utilisation / Temps Total de la Période) x 100.',

  'dashboard.text.totalTimeInUseMinutes.heading': 'Temps Total d’Utilisation (min)',
  'dashboard.text.totalTimeInUseMinutes.description':
    'La durée totale pendant laquelle la borne a été branchée à un véhicule, qu’elle fournisse ou non de l’énergie.',

  'dashboard.text.totalTimeInPeriodMinutes.heading': 'Temps Total dans la Période (min)',
  'dashboard.text.totalTimeInPeriodMinutes.description': 'La durée totale de la période de rapport.',
  'dashboard.text.transactionId.description':
    'Un identifiant unique attribué à chaque session de recharge ou transaction individuelle',
  'dashboard.text.site.description': "L'adresse physique/emplacement des chargeurs EV",
  'dashboard.text.country.description': 'Le pays où se trouvent les chargeurs EV',
  'dashboard.text.state.description': "La province ou l'état où se trouvent les chargeurs EV",
  'dashboard.text.stateProvince.heading': 'Province/État',
  'dashboard.text.transactionState.description':
    'Cela indique si une session de recharge est gratuite ou payante. Les états possibles sont « Gratuit » (indiquant une transaction gratuite) et « Confirmé » (indiquant une transaction payante)',
  'dashboard.text.transactionState.heading': 'État de la transaction',
  'dashboard.text.startTimeLocal.heading': 'Heure de début (locale)',
  'dashboard.text.startTimeLocal.description': "L'heure locale à laquelle une session de recharge a commencé",
  'dashboard.text.endTimeLocal.heading': 'Heure de fin (locale)',
  'dashboard.text.endTimeLocal.description': "L'heure locale à laquelle une session de recharge s'est terminée",
  'dashboard.text.chargingDurationInHours.heading': 'Durée de recharge (h)',
  'dashboard.text.chargingDurationInHours.description':
    "Le temps total, mesuré en heures, pendant lequel un véhicule est en charge active. Cela n'inclut pas le temps pendant lequel le véhicule est branché mais pas en charge active",
  'dashboard.text.energyInkWh.heading': 'Énergie (kWh)',
  'dashboard.text.energyInkWh.description': 'Énergie totale consommée pendant une transaction',
  'dashboard.text.ghgSaving.heading': 'Économies de GES (kg CO2 éq)',
  'dashboard.text.ghgSaving.description':
    "La quantité d'émissions de gaz à effet de serre (GES) économisée, mesurée en kilogrammes d'équivalent dioxyde de carbone (kg CO2 éq)",
  'dashboard.text.driverFee.heading': 'Frais du conducteur',
  'dashboard.text.driverFee.description': "Le montant payé par le conducteur pour la consommation d'énergie",
  'dashboard.text.driverPaymentProcessingFee.heading': 'Frais de traitement du paiement du conducteur',
  'dashboard.text.driverPaymentProcessingFee.description':
    "Souvent appelé Frais d'invité, c'est un frais de 0,50 $ facturé aux conducteurs qui ne créent pas de compte SWTCH et poursuivent en tant qu'invité",
  'dashboard.text.driverTaxPaid.heading': 'Taxe payée par le conducteur',
  'dashboard.text.driverTaxPaid.description':
    'Le montant total de la taxe sur une transaction du conducteur en fonction de la localisation du chargeur et des réglementations fiscales locales',
  'dashboard.text.driverTotalPaid.heading': 'Total payé par le conducteur',
  'dashboard.text.driverTotalPaid.description':
    'Le montant total payé par le conducteur pour la transaction. Somme des frais du conducteur + frais de traitement du paiement du conducteur + taxe payée par le conducteur',
  'dashboard.text.ownerTransactionFee.heading': `Frais de transaction ${currentSiteOwner}`,
  'dashboard.text.ownerTransactionFee.description': `Les frais ${currentSiteOwner} pour la transaction`,
  'dashboard.text.ownerPaymentProcessingFee.heading': `Frais de traitement du paiement ${currentSiteOwner}`,
  'dashboard.text.ownerPaymentProcessingFee.description': `Égal au frais d'invité, également appelé frais de traitement du paiement du conducteur. Il s'agit d'un frais de 0,50 $ facturé aux conducteurs qui ne créent pas de compte ${currentSiteOwner} et poursuivent en tant qu'invité`,
  'dashboard.text.ownerTax.heading': `Taxe ${currentSiteOwner}`,
  'dashboard.text.ownerTax.description': `Dépend du statut d'enregistrement fiscal de la propriété fourni à ${currentSiteOwner} lors de l'intégration.`,
  'dashboard.text.propertySubtotal.heading': 'Sous-total de la propriété',
  'dashboard.text.propertySubtotal.description': `Les gains nets de la propriété pour chaque transaction après déduction des taxes applicables. C'est le montant qui sera payé lors du versement trimestriel si un site N'EST PAS enregistré pour la taxe`,
  'dashboard.text.propertyTax.heading': 'Taxe de propriété',
  'dashboard.text.propertyTax.description': `C'est la partie de la taxe qui sera versée à la propriété lors du versement. S'applique uniquement aux propriétés qui ont partagé leurs détails d'enregistrement fiscal avec ${currentSiteOwner}.`,
  'dashboard.text.propertyTotal.heading': 'Total de la propriété',
  'dashboard.text.propertyTotal.description': `Le montant du versement qu'une propriété recevra après déduction des frais applicables (frais de transaction ${currentSiteOwner}, frais de traitement du paiement ${currentSiteOwner}) et des taxes (taxe ${currentSiteOwner}). C'est le montant qui sera payé lors du versement trimestriel si un site est enregistré pour la taxe et a fourni ces détails à ${currentSiteOwner}.`,
  'dashboard.text.connectorId.description':
    "L'ID du connecteur spécifique de ce chargeur. Généralement 1 ou 2, selon le nombre de connecteurs qu'un chargeur possède.",
  'dashboard.text.address.heading': 'Adresse',
  'dashboard.text.address.description': "L'adresse de la rue où se trouvent les chargeurs EV",
  'dashboard.text.city.description': 'La ville où se trouvent les chargeurs EV',
  'dashboard.text.connectorID.heading': 'ID du connecteur',
  'dashboard.text.connectorID.description':
    "L'ID du connecteur spécifique de ce chargeur. Généralement 1 ou 2, selon le nombre de connecteurs qu'un chargeur possède.",
  'dashboard.text.state.heading': 'État/Province',
  'dashboard.text.city.heading': 'Ville',
  'dashboard.text.country.heading': 'Pays',
  'dashboard.text.sessionStartDate.heading': 'Date de début de session',
  'dashboard.text.sessionStartDate.description': 'Le jour où une session a commencé.',

  'dashboard.text.sessionStartTime.heading': 'Heure de début de session',
  'dashboard.text.sessionStartTime.description': "L'heure à laquelle une session a commencé (UTC).",

  'dashboard.text.sessionEndDate.heading': 'Date de fin de session',
  'dashboard.text.sessionEndDate.description': "Le jour où une session s'est terminée.",

  'dashboard.text.sessionEndTime.heading': 'Heure de fin de session',
  'dashboard.text.sessionEndTime.description': "L'heure à laquelle une session s'est terminée (UTC).",

  'dashboard.text.chargingStartDate.heading': 'Date de début de charge',
  'dashboard.text.chargingStartDate.description': 'Le jour où la charge a commencé.',

  'dashboard.text.chargingStartTime.heading': 'Heure de début de charge',
  'dashboard.text.chargingStartTime.description': "L'heure à laquelle la charge a commencé (UTC).",

  'dashboard.text.chargingEndDate.heading': 'Date de fin de charge',
  'dashboard.text.chargingEndDate.description': "Le jour où la charge s'est terminée.",

  'dashboard.text.chargingEndTime.heading': 'Heure de fin de charge',
  'dashboard.text.chargingEndTime.description': "L'heure à laquelle la charge s'est terminée (UTC).",

  'dashboard.text.timezone.heading': 'Fuseau horaire',
  'dashboard.text.timezone.description': 'Le fuseau horaire dans lequel les données sont rapportées.',

  'dashboard.text.chargingDuration.heading': 'Durée de charge',
  'dashboard.text.chargingDuration.description': 'La durée totale de la charge active en heures, minutes et secondes.',

  'dashboard.text.sessionDuration.heading': 'Durée de session',
  'dashboard.text.sessionDuration.description': 'La durée totale de la session en heures, minutes et secondes.',

  'dashboard.text.loiteringDuration.heading': "Durée d'errance",
  'dashboard.text.loiteringDuration.description':
    "La durée totale passée à stationner sans charger en heures, minutes et secondes (si des pénalités d'errance existent pour le chargeur).",

  'dashboard.text.idlingDuration.heading': "Durée d'inactivité",
  'dashboard.text.idlingDuration.description':
    "La durée totale passée sans charge active au cours d'une session en heures, minutes et secondes.",

  'dashboard.text.totalEnergyProvided.heading': 'Énergie totale fournie',
  'dashboard.text.totalEnergyProvided.description': "L'énergie totale consommée pendant une transaction (kWh).",

  'dashboard.text.ghgSavings.heading': 'Économies de GES (kg CO2 éq)',
  'dashboard.text.ghgSavings.description':
    "La quantité d'émissions de gaz à effet de serre (GES) économisée, mesurée en kilogrammes d'équivalent dioxyde de carbone (kg CO2 éq).",

  'dashboard.text.averagePower.heading': 'Puissance moyenne (kW)',
  'dashboard.text.averagePower.description': 'La puissance moyenne délivrée par le chargeur pendant la transaction.',

  'dashboard.text.peakPower.heading': 'Puissance de pointe',
  'dashboard.text.peakPower.description':
    'La puissance maximale enregistrée par le chargeur pendant la transaction (kW).',

  'dashboard.text.chargerLifetimeEnergyDelivered.heading':
    'Énergie totale fournie par le chargeur au cours de sa durée de vie (kWh)',
  'dashboard.text.chargerLifetimeEnergyDelivered.description':
    "L'énergie totale délivrée rapportée par le chargeur au cours de sa durée de vie.",

  'dashboard.text.chargerMaxOutput.heading': 'Puissance maximale du chargeur (kW)',
  'dashboard.text.chargerMaxOutput.description': 'La puissance maximale nominale du chargeur.',

  'dashboard.text.evseVendor.heading': "Fournisseur de l'EVSE",
  'dashboard.text.evseVendor.description': 'Le fournisseur du chargeur.',

  'dashboard.text.evseModel.heading': "Modèle de l'EVSE",
  'dashboard.text.evseModel.description': 'Le numéro de modèle du chargeur.',

  'dashboard.text.evseSerialNumber.heading': "Numéro de série de l'EVSE",
  'dashboard.text.evseSerialNumber.description': 'Le numéro de série du chargeur.',

  'dashboard.text.connectorType.heading': 'Type de connecteur',
  'dashboard.text.connectorType.description': 'Le type de connecteur du chargeur.',

  'dashboard.text.chargerType.heading': 'Type de chargeur',
  'dashboard.text.chargerType.description': 'Le type de chargeur, soit Niveau 2 soit Charge rapide DC.',
  'dashboard.text.serviceTypeCode.heading': 'Code de Type de Service',
  'dashboard.text.serviceTypeCode.description':
    'Type de système électrique : soit derrière le compteur, soit devant le compteur.',

  'dashboard.text.priceType.heading': 'Type de Prix',
  'dashboard.text.priceType.description': 'L’unité de calcul du coût de recharge. Soit par kWh, soit par heure.',

  'dashboard.text.transactionStatus.heading': 'Statut de la Transaction',
  'dashboard.text.transactionStatus.description':
    'Le statut de la transaction défini comme réussi, en erreur ou partiellement remboursé. Une transaction en erreur est une transaction qui n’a pas abouti avec succès, donc le paiement n’a pas été perçu.',

  'dashboard.text.vehicleMake.heading': 'Marque du Véhicule',
  'dashboard.text.vehicleMake.description':
    'Marque du véhicule utilisant le chargeur, telle que définie par l’utilisateur.',

  'dashboard.text.vehicleModel.heading': 'Modèle du Véhicule',
  'dashboard.text.vehicleModel.description':
    'Modèle du véhicule utilisant le chargeur, tel que défini par l’utilisateur.',
  'dashboard.text.ratedOutput.heading': 'Puissance Nominale (kW)',
  'dashboard.text.ratedOutput.description': 'Puissance nominale totale du chargeur.',

  'dashboard.text.locationSetting.heading': 'Type d’Emplacement',
  'dashboard.text.locationSetting.description':
    'Définit le type de site où le chargeur est installé (par exemple, immeuble résidentiel à plusieurs unités, public, en bordure de rue ou lieu de travail).',

  'dashboard.text.evseStatus.heading': 'Statut EVSE',
  'dashboard.text.evseStatus.description': 'Indique l’état actuel du chargeur, et s’il est actif ou en réparation.',

  'dashboard.text.effectiveStatusDate.heading': 'Date du Statut Actuel',
  'dashboard.text.effectiveStatusDate.description':
    'La date à laquelle le statut actuel du chargeur est entré en vigueur.',

  'dashboard.text.effectiveStatusTime.heading': 'Heure du Statut Actuel',
  'dashboard.text.effectiveStatusTime.description':
    'L’heure à laquelle le statut actuel du chargeur est entré en vigueur (UTC).',

  'dashboard.text.previousStatus.heading': 'Statut Précédent',
  'dashboard.text.previousStatus.description': 'La mise à jour du statut opérationnel précédent du chargeur.',

  'dashboard.text.previousStatusDate.heading': 'Date du Statut Précédent',
  'dashboard.text.previousStatusDate.description':
    'La date à laquelle le statut précédent du chargeur est entré en vigueur.',

  'dashboard.text.previousStatusTime.heading': 'Heure du Statut Précédent',
  'dashboard.text.previousStatusTime.description':
    'L’heure à laquelle le statut précédent du chargeur est entré en vigueur (UTC).',

  'dashboard.text.activationDate.heading': 'Date d’Activation',
  'dashboard.text.activationDate.description': 'La date à laquelle le chargeur est devenu actif sur le réseau.',
  'dashboard.text.siteId.heading': 'ID du site',
  'dashboard.text.siteId.description': 'Un identifiant unique attribué à chaque site',
  'dashboard.text.intervalId.heading': 'ID d’Intervalle',
  'dashboard.text.intervalId.description':
    'Un identifiant unique attribué à chaque intervalle de 15 minutes dans une transaction de recharge.',

  'dashboard.text.portNumber.heading': 'Numéro de Port',
  'dashboard.text.portNumber.description': 'L’identifiant du connecteur spécifique de ce chargeur. Typiquement 1 ou 2.',

  'dashboard.text.intervalStartDate.heading': 'Date de Début de l’Intervalle (UTC)',
  'dashboard.text.intervalStartDate.description': 'Le jour où l’intervalle de 15 minutes a commencé.',

  'dashboard.text.intervalStartTime.heading': 'Heure de Début de l’Intervalle (UTC)',
  'dashboard.text.intervalStartTime.description': 'L’heure à laquelle l’intervalle de 15 minutes a commencé.',

  'dashboard.text.intervalEndDate.heading': 'Date de Fin de l’Intervalle (UTC)',
  'dashboard.text.intervalEndDate.description': 'Le jour où l’intervalle de 15 minutes s’est terminé.',

  'dashboard.text.intervalEndTime.heading': 'Heure de Fin de l’Intervalle (UTC)',
  'dashboard.text.intervalEndTime.description': 'L’heure à laquelle l’intervalle de 15 minutes s’est terminé.',

  'dashboard.text.energyDelivered.heading': 'Énergie Fournie (kWh)',
  'dashboard.text.energyDelivered.description':
    'La quantité totale d’énergie délivrée au véhicule pendant l’intervalle.',

  'dashboard.text.peakDemand.heading': 'Demande de Pointe (kW)',
  'dashboard.text.peakDemand.description':
    'La puissance de sortie la plus élevée enregistrée du chargeur pendant l’intervalle.',

  'dashboard.text.averageDemand.heading': 'Demande Moyenne (kW)',
  'dashboard.text.averageDemand.description': 'La puissance moyenne du chargeur pendant la transaction.',
  'dashboard.text.uptimePercentage.heading': 'Pourcentage de Disponibilité',
  'dashboard.text.uptimePercentage.description':
    'Le pourcentage de temps pendant lequel une borne est en état actif, calculé comme suit : [Temps total sur la période − (Temps total de panne − Temps total exclu) ] / Temps total sur la période × 100',
  'dashboard.text.numberOfOutages.heading': 'Nombre de Pannes',
  'dashboard.text.numberOfOutages.description': 'Le nombre total d’incidents où un chargeur a connu des temps d’arrêt.',

  'dashboard.text.totalOutageTime.heading': 'Temps Total d’Arrêt',
  'dashboard.text.totalOutageTime.description':
    'Le temps cumulé pendant lequel la borne a connu une panne (en minutes).',

  'dashboard.text.totalExcludedTime.heading': 'Temps Total Exclu',
  'dashboard.text.totalExcludedTime.description':
    'Temps d’arrêt dû à des facteurs échappant au contrôle (par ex., des pannes à l’échelle de la ville ou des catastrophes naturelles) qui sont exclus des calculs de disponibilité (minutes).',

  /************************* Load Management Tab *************************/
  'dashboard.loadManagementTab.addMixedCircuit': 'Ajouter un Circuit Mixte',
  'dashboard.loadManagementTab.addDedicatedCircuit': 'Ajouter un Circuit Dédié',
  'dashboard.loadManagementTab.button.sendUpdates': 'Envoyer des mises à jour',
  'dashboard.loadManagementTab.table.locale.unconfig': 'Plus de chargeurs non configurés',
  'dashboard.loadManagementTab.table.locale.panel.title': "Aucun panneau électrique n'est connecté",
  'dashboard.loadManagementTab.table.locale.panel.subtitle':
    'Veuillez utiliser le bouton en haut à gauche pour en créer un',
  'dashboard.loadManagementTab.table.title.unconfig': 'Chargeurs non configurés',
  'dashboard.loadManagementTab.table.locale.branch':
    "Il n'y a pas de branche connectée. Veuillez cliquer sur le bouton Ajouter une branche en haut à droite de ce circuit pour en créer une",
  'dashboard.loadManagementTab.circuitName': 'Nom du circuit',
  'dashboard.loadManagementTab.branchName': 'Nom de la filiale',
  'dashboard.loadManagementTab.editCircuit': 'Modifier le Circuit',
  'dashboard.loadManagementTab.addCircuit': 'Ajouter de la Circuit',
  'dashboard.loadManagementTab.editBranch': 'Modifier de la Filiale',
  'dashboard.loadManagementTab.addBranch': 'Ajouter de la Filiale',
  'dashboard.loadManagementTab.editCharger': 'Modifier le chargeur',
  'dashboard.loadManagementTab.updateCharger': 'Mettre à jour les Chargeur',
  'dashboard.loadManagementTab.type': 'Type de Circuits',
  'dashboard.loadManagementTab.loadManagement': 'Gestion de la Charge',
  'dashboard.loadManagementTab.circuitBranch': 'Circuit/Filiale',
  'dashboard.loadManagementTab.ipAddress': 'Adresse IP',
  'dashboard.loadManagementTab.ctClampConfig': 'Configuration de pince CT pour circuit mixte',
  'dashboard.loadManagementTab.unconfiguredChargers': 'Chargeurs non Configurés',
  'dashboard.loadManagementTab.rule.existingLoad': 'Veuillez entrer une charge existante valide (A)',
  'dashboard.loadManagementTab.rule.circuitSize': 'Veuillez entrer une taille de circuit valide (A)',
  'dashboard.loadManagementTab.rule.ipAddress':
    'Une adresse IP valide comporte quatre nombres décimaux séparés par des points',
  'dashboard.loadManagementTab.rule.ipAddress.warning': 'Veuillez entrer une adresse IP valide',
  'dashboard.loadManagementTab.rule.port': 'veuillez entrer un port valide',
  'dashboard.loadManagementTab.circuitTypeField': 'Type dédié ou mixte',
  'dashboard.loadManagementTab.circuitName.message': 'Veuillez entrer un nom pour le circuit',
  'dashboard.loadManagementTab.label.circuitSize': 'Circuit Valide (A)',
  'dashboard.loadManagementTab.label.existingLoad': 'Existante Valide (A)',
  'dashboard.loadManagementTab.label.maxAmp': 'Capacité Min. (A)',
  'dashboard.loadManagementTab.label.minAmp': 'Capacité Max. (A)',
  'dashboard.loadManagementTab.label.fallbackAmp': 'Repli (A)',
  'dashboard.loadManagementTab.label.breakerSize': 'Taille du Disjoncteur (A)',
  'dashboard.loadManagementTab.label.circuitId': 'ID de Circuit',
  'dashboard.loadManagementTab.cloudLoadMgmt.message':
    "La gestion de la charge cloud de cette annonce a été désactivée. Pour l'activer, cliquez sur la liste, accédez à 'Modifier la liste' et cliquez sur 'Autoriser la gestion de la charge dans le cloud'",
  'dashboard.loadManagementTab.rule.endPoint': 'point final',
  'dashboard.loadManagementTab.text.instanceIP': "IP de l'instance",
  'dashboard.loadManagementTab.text.sendUpdates': 'Envoyer des mises à jour',
  'dashboard.loadManagementTab.rule.http': 'Doit être un protocole HTTPS ou HTTP',
  'dashboard.loadManagementTab.result.title.tenantAllowLoadMgmt':
    'Actuellement, la gestion de la charge est désactivée pour ce locataire',
  'dashboard.loadManagementTab.result.subTitle.tenantAllowLoadMgmt':
    'Pour autoriser la gestion de la charge, cliquez sur le bouton Activer ci-dessous',
  'dashboard.loadManagementTab.disableLoadMgmt': 'Désactiver la gestion de la charge',
  'dashboard.loadManagementTab.result.title.error': "L'activation de la gestion de la charge a échoué",
  'dashboard.loadManagementTab.result.subtitle.error':
    "Veuillez vérifier si le locataire est capable de gérer la charge et contactez l'administrateur pour obtenir de l'aide",
  'dashboard.loadManagementTab.missingListing.message':
    "Ce chargeur n'a pas de liste. Pour joindre une annonce, cliquez sur le bouton Ajouter une annonce",
  'dashboard.loadManagementTab.button.addListing': 'Ajouter une annonce',
  'dashboard.loadManagementTab.maxCap.message': 'Ne peut pas être supérieur au plafond maximal',
  'dashboard.loadManagementTab.rule.branchName': 'Le nom de la succursale doit être différent',
  'dashboard.loadManagementTab.addBranch.toolTip.message':
    'Pour chaque N chargeurs non configurés, il ne peut y avoir que N branches',

  /******************* Plan and Subscription Page *********************/
  'dashboard.subscriptionsPage.button.title': 'Créer un plan',
  'dashboard.subscriptionsPage.placeholder.searchPlan': 'Plan de recherche',
  'dashboard.subscriptionsPage.createPlanForm.selectPlan.warning': 'Un plan doit être sélectionné pour traiter',
  'dashboard.subscriptionsPage.createPlanForm.searchUser.warning':
    "Pas de résultat trouvé. Veuillez confirmer que l'utilisateur a crée un compte SWTCH avec l'adresse e-mail indiquée.",
  'dashboard.subscriptionsPage.inviteForm.help.start': "Le début de l'abonnement",
  'dashboard.subscriptionsPage.inviteForm.help.end': "La fin de l'abonnement",
  'dashboard.subscriptionsPage.invitePlanForm.user.warning': 'Un utilisateur doit être sélectionné pour traiter',
  'dashboard.plan.modal.delete': 'Êtes-vous sûr de vouloir supprimer le plan ',
  'dashboard.plan.modal.archive': 'Êtes-vous sûr de vouloir archiver le plan ',
  'dashboard.plan.modal.unarchive': 'Êtes-vous sûr de vouloir désarchiver le plan ',
  'dashboard.subscriptionsPage.modal.delete': "Êtes-vous sûr de vouloir annuler l'abonnement pour ",
  'dashboard.subscriptionsPage.modal.pause': "Etes-vous sûr de vouloir suspendre l'abonnement pour ",
  'dashboard.subscriptionsPage.modal.unpause': "Etes-vous sûr de vouloir réactiver l'abonnement pour ",
  'dashboard.subscriptionsPage.editSubscription': "Modifier l'abonnement",
  'dashboard.subscriptionsPage.deleteSubscription': "Supprimer l'abonnement",
  'dashboard.plan.subscriptionPlan': "Formules d'abonnement",
  'dashboard.plan.subscribeUser': "Abonnez-vous à l'utilisateur",
  'dashboard.plan.editPlan': 'Modifier le forfait',
  'dashboard.plan.togglePlan': 'Basculer le plan',
  'dashboard.text.subscribeNewUser': "S'abonner Nouvel utilisateur",
  'dashboard.text.cancelSubscription': "Annuler l'abonnement",
  'dashboard.text.pauseSubscription': "Suspendre l'abonnement",
  'dashboard.text.unpauseSubscription': "Annuler la pause de l'abonnement",

  /*************************** Create Listing Page ***************************/
  'dashboard.listing.ListingsText': 'Annonces',
  'dashboard.listing.createListingRequiredText': "Le titre de l'annonce est requis",
  'dashboard.listing.listingAlreadyExistsText': "Le titre de l'annonce existe déjà",
  'dashboard.listing.CreateNewListing': 'Créer une annonce',
  'dashboard.listing.ListingsTable.titleText': 'Titre',
  'dashboard.listing.ListingsTable.authorText': 'Auteur',
  'dashboard.listing.ListingsTable.categoryText': 'Catégorie',
  'dashboard.listing.ListingsTable.chargerText': 'Chargeur',
  'dashboard.listing.ListingsTable.connectorIdText': 'ID de connecteur',
  'dashboard.listing.ListingsTable.priceText': 'Prix',
  'dashboard.listing.ListingsTable.priceTypeText': 'Type de prix',
  'dashboard.listing.ListingsTable.activeText': 'Actif',
  'dashboard.listing.ListingsTable.activeText.open': 'Ouvert',
  'dashboard.listing.ListingsTable.activeText.closed': 'Fermé',
  'dashboard.createNewListing.txt': 'Créer une annonce',
  'dashboard.createNewListing.ListingTab': 'Référencement',
  'dashboard.createNewListing.chargerTab': 'Chargeur',
  'dashboard.createNewListing.loiteringTab': 'Flâner',
  'dashboard.createNewListing.priceTab': 'Prix',
  'dashboard.createNewListing.commissionTab': 'Frais de commission',
  'dashboard.createNewListing.reviewTab': 'Examen',
  'dashboard.createNewListing.nextBtn': 'Prochain',
  'dashboard.createNewListing.previousBtn': 'Précédent',
  'dashboard.createNewListing.submitBtn': 'Soumettre',
  'dashboard.createNewListing.listingStep.heading': "Titre de l'annonce",
  'dashboard.createNewListing.listingStep.detailedDescription.heading': "Informations sur l'inscription",
  'dashboard.createNewListing.listingStep.detailedDescription.warning': '255 caractères est la limite',
  'dashboard.createNewListing.listingStep.detailedDescription.info':
    'Si votre description contient des liens YouTube, les vidéos seront affichées sous la description',
  'dashboard.createNewListing.listingStep.listingAuthor.heading': "Auteur de l'annonce",
  'dashboard.createNewListing.listingStep.listingAuthor.selectTxt': "Sélectionnez l'auteur",
  'dashboard.createNewListing.listingStep.listingAuthor.warning': "L'auteur de l'annonce est requis",
  'dashboard.createNewListing.listingStep.stripeAccount.heading': 'Un compte Stripe est requis',
  'dashboard.createNewListing.listingStep.stripeAccount.selectTxt': 'Sélectionnez un compte Stripe',
  'dashboard.createNewListing.listingStep.listingCategory.Txt': "Catégorie d'annonce",
  'dashboard.createNewListing.listingStep.listingCategory.Reservation': 'Réservation',
  'dashboard.createNewListing.listingStep.listingCategory.Non Reservation': 'Sans Réserve',
  'dashboard.createNewListing.listingStep.listingCategory.no_reservation': 'Sans Réserve',
  'dashboard.createNewListing.listingStep.listingCategory.non_reservation': 'Sans Réserve',
  'dashboard.createNewListing.listingStep.listingCategory.Destination': 'Destination',
  'dashboard.createNewListing.listingStep.listingCategory.Non Network': 'Hors réseau',
  'dashboard.createNewListing.listingStep.listingCategory.Free': 'Gratuite',
  'dashboard.createNewListing.listingStep.currencyCategory.txt': 'Devise',
  'dashboard.createNewListing.listingStep.walletCategory.txt': 'Fonctionnalité du portefeuille',
  'dashboard.createNewListing.listingStep.walletCategory.walletOption': 'Porte monnaie',
  'dashboard.createNewListing.listingStep.walletCategory.nowalletOption': 'Pas de portefeuille',
  'dashboard.createNewListing.listingStep.listingCategory.txt': "Contrôle d'accès",
  'dashboard.createNewListing.listingStep.listingCategory.privateOption': 'Limité',
  'dashboard.createNewListing.listingStep.listingCategory.publicOption': 'Ouvert',
  'dashboard.createNewListing.listingStep.hiddenCategory.txt': 'Annonces cachées de la carte',
  'dashboard.createNewListing.listingStep.carDetailsCategory.txt':
    'Les utilisateurs ont besoin des détails de la voiture pour réserver',
  'dashboard.createNewListing.chargerStep.tenantCategory.txt': 'Site associé',
  'dashboard.createNewListing.chargerStep.tenantCategory.warning': 'Le site est requis',
  'dashboard.createNewListing.chargerStep.chargerOutput.txt': 'Sortie chargeur (kW)',
  'dashboard.createNewListing.chargerStep.chargerOutput.timeOfDayCharging.txt':
    "Sélectionnez si l'heure de la journée de charge",
  'dashboard.createNewListing.chargerStep.customNotification.txt': 'Avis personnalisé',
  'dashboard.createNewListing.chargerStep.customNotification.info':
    'Fournir des instructions sur la façon de faire fonctionner le chargeur si nécessaire',
  'dashboard.createNewListing.priceStep.allowClouldLoadManagement.txt':
    'Autoriser la gestion de la charge dans le cloud',
  'dashboard.createNewListing.priceStep.localLoadManagementAutoStop.txt':
    'Gestion de la charge locale : arrêt automatique des transactions',
  'dashboard.createNewListing.priceStep.preferredPricing.txt': 'Tarification préférentielle pour les membres sites',
  'dashboard.createNewListing.comissionStep.txt': 'Commission (%)',
  'dashboard.createNewListing.comissionStep.guestFee.txt': "Frais d'invité ($)",
  'dashboard.createNewListing.comissionStep.paymentLimit.txt': 'Limite de paiement ($)',
  'dashboard.createNewListing.reviewStep.listing.title': 'Titre',
  'dashboard.createNewListing.reviewStep.listing.category': 'Catégorie',
  'dashboard.createNewListing.reviewStep.listing.paymentType': 'Type de paiement',
  'dashboard.createNewListing.reviewStep.listing.accessType': "Type d'accès",
  'dashboard.createNewListing.reviewStep.listing.hiddenFromMap': 'Caché de la carte',
  'dashboard.createNewListing.reviewStep.listing.carDetailRequired': 'Détail de la voiture requis',
  'dashboard.createNewListing.reviewStep.listing.author': 'Auteur',
  'dashboard.createNewListing.reviewStep.listing.stripeAccount': 'Compte Stripe',
  'dashboard.createNewListing.reviewStep.listing.currency': 'Devise',
  'dashboard.createNewListing.reviewStep.listing.description': "Description de l'emplacement",
  'dashboard.createNewListing.reviewStep.charger.chargerOutput': 'Sortie chargeur',
  'dashboard.createNewListing.reviewStep.charger.enableTimeOfDayCharging':
    "Activer la charge selon l'heure de la journée",
  'dashboard.createNewListing.reviewStep.charger.customNotification': 'Avis personnalisé',
  'dashboard.createNewListing.reviewStep.price.preferedPricingtxt': 'Prix ​​préférentiel par unité Cents ($)',
  'dashboard.createNewListing.reviewStep.price.hourlyPricingtxt': 'Tarification horaire basée sur le kWh ($)',
  'dashboard.createNewListing.reviewStep.price.todPriceSettingstxt': "Paramètres de prix selon l'heure de la journée",
  'dashboard.createNewListing.listingCreatedSuccessfullyText': 'Liste -1 mis à jour avec succés',
  'dashboard.updated.listing': 'La liste a été mise à jour avec succès',
  'dashboard.createNewListing.refreshAuthorsText': 'Actualiser les auteurs et les comptes Stripe',
  'dashboard.listingAccess.carSettingsText': 'Paramètres de la voiture',
  'dashboard.listingAccess.noCarDetailsRequiredText': 'Aucun détail de voiture requis',
  'dashboard.listingAccess.carDetailsRequiredText': 'Détails de la voiture requis',
  'dashboard.listingAccess.mapSettingsText': 'Paramètres de la carte',
  'dashboard.listingAccess.hideListingText': "Masquer l'annonce sur la carte",
  'dashboard.listingAccess.displayListingText': 'Afficher la liste sur la carte',

  /*************************** Peak Shaving Programs Page ***************************/
  'dashboard.peakShaving': 'Gestion de la demande',
  'dashboard.peakShaving.programsPage': 'Programmes',
  'dashboard.peakShaving.programsPage.searchPrograms': 'Rechercher des programme',
  'dashboard.peakShaving.programsPage.programStatus': 'Statut du programme',
  'dashboard.peakShaving.programsPage.newProgram': 'Nouveau programme',
  'dashboard.peakShaving.programsPage.status.published': 'Publié',
  'dashboard.peakShaving.programsPage.status.draft': 'Brouillon',
  'dashboard.peakShaving.programsPage.status.archived': 'Archivé',
  'dashboard.peakShaving.programsPage.status.finished': 'Fini',
  'dashboard.peakShaving.programsPage.programCreatedSuccessMessage': 'Le programme -1 a été créé avec succès',
  'dashboard.peakShaving.programsPage.programUpdatedSuccessMessage': 'Détails du programme mis à jour avec succès',
  'dashboard.peakShaving.programPage.program': 'Programme',
  'dashboard.peakShaving.programPage.programName': 'Nom du programme',
  'dashboard.peakShaving.programPage.program.cannotExceed': '-1 ne peut pas dépasser 255 caractères !',
  'dashboard.peakShaving.programPage.aggregatorName': "Nom de l'agrégateur",
  'dashboard.peakShaving.programPage.status': 'Statut',
  'dashboard.peakShaving.programPage.participant': 'Participant',
  'dashboard.peakShaving.programPage.participants': 'Participants',
  'dashboard.peakShaving.programPage.participantID': 'D de participant',
  'dashboard.peakShaving.programPage.allListings': 'Toutes les annonces',
  'dashboard.peakShaving.programPage.selectListings': 'Sélectionner des annonces',
  'dashboard.peakShaving.programPage.selectedListings': 'Annonces sélectionnées',
  'dashboard.peakShaving.programPage.mixed': 'Mixte',
  'dashboard.peakShaving.programPage.addParticipants': 'Ajouter des participants',
  'dashboard.peakShaving.programPage.addParticipants.success': 'Participant ajouté avec succès',
  'dashboard.peakShaving.programPage.updateParticipants.success': 'Participant mis à jour avec succès',
  'dashboard.peakShaving.programPage.editParticipants': 'Modifier les participants',
  'dashboard.peakShaving.programPage.program.type': 'Type',
  'dashboard.peakShaving.programPage.program.date': 'Date',
  'dashboard.peakShaving.programPage.program.participationWindow': 'Fenêtre de Participation',
  'dashboard.peakShaving.programPage.program.eventNotification': 'Notification d’Événement',
  'dashboard.peakShaving.programPage.program.voluntary': 'Volontaire',
  'dashboard.peakShaving.programPage.program.nonVoluntary': 'Non-volontaire',
  'dashboard.peakShaving.programPage.program.participationDetails': 'Détails de la fenêtre de participation',
  'dashboard.peakShaving.programPage.program.participationDetails.placeholder':
    'Détails de la fenêtre de participation (fenêtre de 4 heures)',
  'dashboard.peakShaving.programPage.program.eventNotificationDetails':
    'Détails de la fenêtre de notification d’événement',
  'dashboard.peakShaving.programPage.program.eventNotificationDetails.placeholder':
    'Détails de la fenêtre de notification d’événement (par ex. préavis de 2 heures)',
  'dashboard.peakShaving.programPage.program.newEventBtn': 'Nouvel événement',
  'dashboard.peakShaving.programPage.program.archiveBtn': "Programme d'archivage",
  'dashboard.peakShaving.programPage.program.archiveWarning':
    "Tous les événements publiés seront annulés et vous ne pourrez plus ajouter d'annonces ou d'événements au programme.",
  'dashboard.peakShaving.programPage.program.programsDetailTab': 'Détails du programme',
  'dashboard.peakShaving.programPage.program.eventsTab': 'Événements',
  'dashboard.peakShaving.programPage.program.publishProgram': 'Publier le programme',
  'dashboard.peakShaving.programPage.program.publishInfoText': 'Publier le programme pour ajouter des événements',
  'dashboard.peakShaving.programPage.program.schedule': 'Calendrier',
  'dashboard.peakShaving.programPage.program.schedule.updatedBy': 'Mis à jour par',
  'dashboard.peakShaving.programPage.program.schedule.allowOptOut': 'Autoriser la désinscription',
  'dashboard.peakShaving.programPage.program.schedule.editSchedule': 'Modifier le calendrier',
  'dashboard.peakShaving.programPage.program.schedule.removeSchedule': 'Supprimer le programme',
  'dashboard.peakShaving.programPage.program.eventsTable.info':
    "Une fois qu'un événement est généré, il apparaîtra ici",
  'dashboard.peakShaving.programPage.program.eventsTable.eventDate': 'Date de l événement',
  'dashboard.peakShaving.programPage.program.eventsTable.eventStatus': 'Statut de l événement',
  'dashboard.peakShaving.programPage.program.eventsTable.duplicateEvent': 'Dupliquer l événement',
  'dashboard.peakShaving.programPage.program.eventsTable.cancelEvent': 'Annuler l événement',
  'dashboard.peakShaving.programPage.modal.publishText': 'Publier',
  'dashboard.peakShaving.programPage.modal.zoneText': 'Zone',
  'dashboard.peakShaving.programPage.modal.startTimeText': 'Heure de début',
  'dashboard.peakShaving.programPage.modal.endTimeText': 'Heure de fin',
  'dashboard.peakShaving.programPage.modal.frequencyText': 'Fréquence',
  'dashboard.peakShaving.programPage.modal.eventScheduleText': 'Calendrier des événements',
  'dashboard.peakShaving.programPage.modal.createEventText': 'Créer un événement',
  'dashboard.peakShaving.programPage.modal.zone.none': 'Aucun',
  'dashboard.peakShaving.programPage.modal.zone.primary': 'Zone de pointe primaire',
  'dashboard.peakShaving.programPage.modal.zone.secondary': 'Zone de pointe secondaire',
  'dashboard.peakShaving.programPage.modal.createScheduleText': 'Créer un calendrier',
  'dashboard.peakShaving.programPage.modal.zoneWarning': 'Veuillez sélectionner une zone !',
  'dashboard.peakShaving.programPage.modal.startTimeWarning': 'Veuillez sélectionner une heure de début !',
  'dashboard.peakShaving.programPage.modal.createNewProgramText': 'Créer un nouveau programme',
  'dashboard.peakShaving.programPage.modal.updateProgramText': 'Mettre à jour le programme',
  'dashboard.peakShaving.programPage.modal.nameRequiredWarningText': 'Le -1 est requis!',
  'dashboard.peakShaving.programPage.modal.removeSchedule.heading': 'supprimer le planning',
  'dashboard.peakShaving.programPage.modal.removeSchedule.warning': 'Êtes-vous sûr de vouloir supprimer le planning ?',
  'dashboard.peakShaving.programPage.modal.cancelEvent.heading': 'Annuler l événement',
  'dashboard.peakShaving.programPage.modal.cancelEvent.warning': 'Êtes-vous sûr de vouloir annuler l événement ?',
  'dashboard.peakShaving.programPage.modal.createEvent.success': "L'événement a été créé avec succès !",
  'dashboard.peakShaving.programPage.modal.updateEvent.success': 'Événement et calendrier mis à jour avec succès !',
  'dashboard.peakShaving.programPage.modal.dailyEvent.update.success':
    'Événement quotidien et calendrier mis à jour avec succès !',
  'dashboard.peakShaving.programPage.modal.dailyEvent.create.success':
    'Le programme quotidien a été créé avec succès !',
  'dashboard.peakShaving.programPage.listing.helper': 'Seuls les listings de ce locataire seront ajoutés',
  'dashboard.peakShaving.programPage.listing.outputText': 'Sortir',
  'dashboard.peakShaving.programPage.listingsTable.info': 'Ajouter des annonces pour publier le programme',
  'dashboard.peakShaving.programPage.participantTable.info': 'Ajouter un participant pour publier le programme',
  'dashboard.peakShaving.programPage.modal.listings.addListings': 'Ajouter des annonces',
  'dashboard.peakShaving.programPage.modal.listings.addListings.warning':
    'Veuillez saisir une annonce dans le même fuseau horaire -1 que "-2"',
  'dashboard.peakShaving.programPage.modal.listings.addListings.success':
    '-1 Les annonces ont été ajoutées au programme avec succès!',
  'dashboard.peakShaving.programPage.dailySchedule.info':
    'Si un planning est créé, le système publiera et générera des événements en fonction du planning défini.',
  'dashboard.peakShaving.programPage.removeListing': 'Supprimer l annonce',
  'dashboard.peakShaving.programPage.removeListing.info': 'Êtes-vous sûr de vouloir supprimer la liste du programme ?',
  'dashboard.peakShaving.programPage.removeParticipant': 'Supprimer le participant',
  'dashboard.peakShaving.programPage.removeParticipant.info':
    'Êtes-vous sûr de vouloir retirer le participant du programme ?',
  'dashboard.peakShaving.programPage.removeTenant': 'Supprimer le locataire',
  'dashboard.peakShaving.programPage.removeTenant.info':
    'Êtes-vous sûr de vouloir supprimer le locataire du programme?',
  'dashboard.peakShaving.eventModal.charging': '-1 Chargement',
  'dashboard.peakShaving.eventModal.fullSpeed': '-1 Pleine vitesse',
  'dashboard.peakShaving.eventModal.warning.timeRange': 'La plage horaire chevauche une entrée existante.',
  'dashboard.peakShaving.eventModal.editEvent': "Modifier l'événement",
  'dashboard.peakShaving.eventModal.oneTime': 'Une seule fois',
  'dashboard.peakShaving.eventModal.selectDate': 'Sélectionner une date',
  'dashboard.peakShaving.eventModal.curtailment': 'Réduction',
  'dashboard.peakShaving.eventModal.to': 'À',
  'dashboard.peakShaving.programTab.title': 'Programmes',
  'dashboard.peakShaving.programTab.programSelector': 'Programme(s)',
  'dashboard.peakShaving.participantsTab.delete': 'Supprimer le participant',
  'dashboard.peakShaving.participantsTab.delete.warning':
    'Tous les événements de réduction associés à ce participant seront annulés',
  'dashboard.peakShaving.participantsTab.edit': 'Modifier le participant',
  'dashboard.peakShaving.participantsTab.new': 'Nouveau participant',
  'dashboard.peakShaving.eventsTab.deleteEvent': "Supprimer l'événement",
  'dashboard.peakShaving.eventsTab.deleteEvent.warning': 'Êtes-vous sûr de vouloir supprimer cet événement?',
  'dashboard.peakShaving.eventsTab.cancelEvent': 'Êtes-vous sûr de vouloir annuler cet événement?',
  'dashboard.peakShaving.eventsTab.editProgram': 'Modifier le programme',

  /*********************** New Feature Release Note ***********************/
  'dashboard.text.releaseNote.heading': 'Mise à jour passionnante : Nouvelle page de rapports ajoutée!',
  'dashboard.text.releaseNote.newFeature':
    "Nous avons mis à jour la page des transactions pour offrir une expérience moderne et conviviale, simplifiant la surveillance des activités de charge. Nous avons également introduit une page de rapports dédiée, vous permettant d'accéder facilement à des rapports détaillés sur l'utilisation et les revenus, ce qui simplifie la gestion de votre entreprise de recharge de véhicules électriques.",
  'dashboard.text.releaseNote.button': 'Explorer la fonctionnalité',
  /*************************** Support Dashboard Page ***************************/
  'dashboard.supportDashboard': "Tableau de bord d'assistance",
  /*************************** Tagging Page ***************************/
  'dashboard.tagging': 'Étiquetage',
  'dashboard.tags': "L'Étiquette",
  'dashboard.tagging.createTag': 'Créer une étiquette',
  'dashboard.tagging.addNewTag': 'Ajouter une nouvelle étiquette',
  'dashboard.tagging.table.id': 'ID',
  'dashboard.tagging.table.name': 'Nom',
  'dashboard.tagging.table.object': 'Objet',
  'dashboard.tagging.table.status': 'Statut',
  'dashboard.tagging.table.type': 'Taper',
  'dashboard.tagging.table.createdAt': 'Créé le',
  'dashboard.tagging.table.createdBy': 'Créé par',
  'dashboard.tagging.table.note': 'Note',
  'dashboard.tagging.table.edit': 'Modifier',
  'dashboard.tagging.table.inactivate': 'Désactiver',

  'dashboard.tagging.modal.createTag': 'Créer une étiquette',
  'dashboard.tagging.modal.editTag': "Modifier l'étiquette",
  'dashboard.tagging.modal.active': 'Actif',
  'dashboard.tagging.modal.inactive': 'Inactif',
  'dashboard.tagging.modal.system': 'Système',
  'dashboard.tagging.modal.admin': 'Admin',
  'dashboard.tagging.modal.customer': 'Client',

  'dashboard.tagging.modal.createSuccess': "L'étiquette -1 a été créée avec succès",
  'dashboard.tagging.modal.editSuccess': "L'étiquette a été mise à jour avec succès",

  /*************************** Discount Schema ***************************/
  'dashboard.discountSchema.archiveDescription': 'Archivage du plan de remise?',
  'dashboard.discountSchema.actionDescription': 'Cette action ne peut pas être annulée.',
  'dashboard.discountSchema.promoCodeDescription': 'Le code promo ne fonctionnera plus',
  'dashboard.discountSchema.usersRemovedDescription': 'Tous les utilisateurs seront supprimés de ce plan de réduction',
  'dashboard.discountSchema.promoCode': 'Code promo',
  'dashboard.discountSchema.skipDiscountPlan': 'Ignorer le plan de réduction',
  'dashboard.discountSchema.flatFeeInfo':
    'Le montant des frais forfaitaires est déterminé par le forfait de base. Choisissez ce montant ou 0,00 $',
  'dashboard.discountSchema.promoCodeInfo':
    'Chaque code doit être unique, comportant uniquement des lettres et des chiffres.',
  'dashboard.discountSchema.discountPlanName': 'Nom du plan de réduction',
  'dashboard.discountSchema.planName': 'Nom du régime',
  'dashboard.discountSchema.basePricingPlan': 'Forfait de base',
  'dashboard.discountSchema.noLoiteringInfoText':
    "L'ajustement du flânage n'est pas disponible car il n'y a pas de flânage dans le plan de base.",
  'dashboard.discountSchema.noFlatFeeText': 'Pas de frais forfaitaires',
  'dashboard.discountSchema.noLoiteringText': 'Pas flânage',
  'dashboard.discountSchema.discountPlanInfoText': 'Le plan de réduction ne peut pas être supérieur au plan de base.',
  'dashboard.discountSchema.gracePeriodText': 'Plan tarifaire de base',
  'dashboard.discountSchema.timeLimittext': 'Limite de temps',
  'dashboard.discountSchema.selectPricingPlanText':
    'Sélectionnez un plan tarifaire où se trouvera le nouveau plan de remise.',
  'dashboard.discountSchema.discountPlanWorksWithPricingPlanText':
    'Un plan de remise fonctionne à l’intérieur d’un plan tarifaire.',
  'dashboard.discountSchema.pricingPlan': 'Plan de tarification:',
  'dashboard.discountSchema.discountPlan': 'Plan de remise:',
  'dashboard.discountSchema.discountPlanTxt': 'Plan de remise',
  'dashboard.discountSchema.listings': 'Annonces:',
  'dashboard.placeholder.email': 'E-mail',
  'dashboard.discountSchema.timeAdded': 'Heure ajoutée',
  'dashboard.discountSchema.action': 'Action',
  'dashboard.discountSchema.updatedBy': 'Mis à jour par',
  'dashboard.discountSchema.manageUsers': 'Gérer les utilisateurs',
  'dashboard.discountSchema.allUsersSelected': 'Tous les -1 utilisateurs de cette page sont sélectionnés.',
  'dashboard.discountSchema.usersSelected': 'Utilisateurs sélectionnés',
  'dashboard.discountSchema.selectAll': 'Sélectionner tous les -1 utilisateurs dans ce plan de remise',
  'dashboard.discountSchema.removingUsers': 'Suppression des utilisateurs?',
  'dashboard.discountSchema.remove': 'Supprimer',
  'dashboard.discountSchema.currentUsers': '-1 Utilisateurs',
  'dashboard.discountSchema.userAuthorizationHistory': 'Historique des autorisations des utilisateurs',
  'dashboard.discountSchema.viewHistory': "Voir l'historique",
  'dashboard.discountSchema.usersRemoved': 'Utilisateurs supprimés',
  'dashboard.discountSchema.usersAdded': 'Utilisateurs ajoutés',
  'dashboard.discountSchema.flatFee': 'Tarif forfaitaire',
  'dashboard.discountSchema.discountPlanModalTitle': 'Plan de réduction modal',

  /*************************** Incidences ***************************/
  'dashboard.incidences.zendeskId': 'ID Zendesk',
  'dashboard.incidences.listingTitle': "Titre de l'annonce",
  'dashboard.incidences.rootCause': 'Cause principale',
  'dashboard.incidences.rootCauses': 'Causes principales',
  'dashboard.incidences.downtime': "Temps d'arrêt",
  'dashboard.incidences.resolution': 'Résolution',
  'dashboard.incidences.resolutions': 'Résolutions',
  'dashboard.incidences.startTIme': 'Heure de début',
  'dashboard.incidences.endTime': 'Heure de fin',
  'dashboard.incidences.duration': 'Durée',
  'dashboard.incidences.include': 'Inclus',
  'dashboard.incidences.exclude': 'Exclus',
  'dashboard.incidences.editIncidence': "Modifier l'incident",
  'dashboard.incidence.updateSuccess': "Mise à jour de l'incident réussie",

  /*************************** Access Plan ***************************/
  'dashboard.accessPlan.createAnAccessPlan': "Créer un plan d'accès",
  'dashboard.accessPlan.accessPlanName': "Nom du plan d'accès",
  'dashboard.accessPlan.customDays': 'Jours personnalisés',
  'dashboard.accessPlan.noRestrictionsOnAccess': "Aucune restriction sur l'accès au chargeur",
  'dashboard.accessPlan.restrictedAccess': "Accès restreint via code d'accès ou domaine de messagerie",
  'dashboard.accessPlan.restrictBasedOn': 'Restreindre en fonction de',
  'dashboard.accessPlan.assignedDriversFromUsers':
    "Les pilotes attribués à partir de la page utilisateur ont automatiquement accès. Modifiez l'accès sur la page des utilisateurs si nécessaire.",
  'dashboard.accessPlan.usersGetAccessByAccessCode': "Les utilisateurs ont accès en entrant ce code d'accès",
  'dashboard.accessPlan.usersGetAccessByEmail': `Les utilisateurs obtiennent l'accès en créant un compte ${currentSiteOwner} avec ce domaine de messagerie (par exemple, someschool.edu)`,
  'dashboard.accessPlan.emailDomain': 'Domaine de messagerie',
  'dashboard.accessPlan.drivers': 'Pilotes',
  'dashboard.accessPlan.nameToKeepTrack': 'Un nom pour vous aider à garder une trace',
  'dashboard.accessPlan.chargerIds': 'Identifiant(s) du chargeur',
  'dashboard.accessPlan.enterEmailDomains': 'Entrez les domaines de messagerie',
  'dashboard.accessPlan.addTimeSchedule': 'AJOUTER UN HORAIRE',
  'dashboard.accessPlan.currentAssignedDrivers': '-1 Pilotes assignés',
  'dashboard.accessPlan.accessDetails': "Détails d'accès",
  'dashboard.accessPlan.accessPlanFlex':
    "Les plans d'accès vous permettent de contrôler l'accès aux bornes de recharge par différents horaires et/ou par différents groupes de conducteurs",
  'dashboard.accessPlan.chargersNotLinked': "Chargeurs non liés à aucun plan d'accès",
  'dashboard.accessPlan.assignedDrivers': 'Chauffeurs assignés',
  'dashboard.accessPlan.allAssigned': 'Tous assignés',
  'dashboard.accessPlan.addAccessPlanStart': "Ajouter un plan d'accès pour commencer",
  'dashboard.accessPlan.conflictingAccessPlan': "Plan d'accès conflictuel",
  'dashboard.accessPlan.chargerInUse':
    "Le chargeur {id} fait déjà partie d'un autre plan d'accès avec un horaire conflictuel.",
  'dashboard.accessPlan.moveChargerSched': 'Déplacez {id} de {name} ou revenez en arrière et ajustez le calendrier.',
  'dashboard.accessPlan.moveCharger': 'DÉPLACER LE CHARGEUR',
  'dashboard.accessPlan.chargersToolTip':
    "Les bornes de recharge qui ne sont pas liées à un plan d'accès ne seront accessibles à aucun moment.\n Ajoutez ces bornes à un plan d'accès existant ou créez un nouveau plan d'accès pour garantir l'accès des conducteurs.",
  'dashboard.accessPlan.noAccessPlanLinked': "Aucun plan d'accès lié à {listing}",
  'dashboard.accessPlan.removeListingAnyways':
    "{listing} a été supprimé de ce plan d'accès et n'est plus lié à aucun plan d'accès. Êtes-vous sûr de vouloir continuer?",
  'dashboard.accessPlan.chargersOverlap':
    "Les chargeurs de ce plan appartiennent actuellement à des plans d'accès existants dont les horaires se chevauchent.",
  'dashboard.accessPlan.moveChargers':
    "Déplacez ces chargeurs de tous les plans d'accès en conflit existants, ou revenez en arrière et ajustez le calendrier.",
  'dashboard.accessPlan.accessPlanCreated': "Plan d'accès créé",
  'dashboard.accessPlan.accessPlanUpdated': "Plan d'accès mis à jour",
  'dashboard.text.viewAccessPlanDetails': 'Voir les détails du plan d’accès',
  'dashboard.text.accessPlanDetails': 'Détails du plan d’accès',
  'dashboard.text.viewSchedules': 'Voir les horaires',
  'dashboard.text.noAccessPlan': 'Aucun plan d’accès',
  'dashboard.text.accessPlansFor': 'Plan d’accès pour {listingTitle}',
  'dashboard.accessPlan.accessCodeAlreadyInUse': "Code d'accès déjà utilisé",
  'dashboard.accessPlan.accessCode.characterLength': 'Le code d’accès doit comporter entre {min} et {max} caractères',
}
