import { AccessPlan, AccessPlanPayload } from './access-plan'
import { Charger } from './charger'
import { Connector } from './connector'
import { DiscountSchema } from './discount'
import { Subscription } from './subscription'
import { TenantRef } from './tenant'
import { Wallet, WalletStatus } from './wallet'

export interface UserSession {
  id: number
  a: boolean
  token: string
  expiresAt?: number
}

export interface UserRef {
  id: string
  name: string
  email: string
  note?: string
}

export interface UserInviteInfo {
  id: string
  name: string
  email: string
}

export interface UserWallet extends UserRef {
  wallet: Wallet
  hasAlternatePaymentMethod: boolean
  hasBillingAddr: boolean
  billingAddr: StripeCustomer
  walletStatus: WalletStatus
  note: string
}

export interface UserEmailResponse {
  email_exist?: boolean
  person_id?: string
  person_name?: string
  error?: string
}

export interface StripeCustomer {
  id: number
  person_id: string
  stripe_customer_id: string
  stripe_payment_gateway_id: number
  line1?: string
  line2?: string
  city?: string
  state: string
  zip: string
  country: string
}

export const userRole = ['admin', 'support', 'user']
export type UserRole = 'admin' | 'support' | 'user'
// Admin: Swtch Employee should have access to everything
// Support: Swtch Support staff
// User: Swtch User, he could be a tenant manager, tenant viewer, a driver. You need to see ressource acesses
// to figure out individual roles

export interface User extends UserRef, Subscriber {
  role: UserRole
  accesses: ResourceAccess[]
  accessPlans: AccessPlanPayload[]
}

export interface Subscriber extends UserRef {
  subscriptions?: Subscription[]
}
export interface UserUpdate {
  driver: boolean
  access?: DashboardAccessType
  name: string
}

export type PermissionType =
  | 'tenant.manager'
  | 'tenant.viewer'
  | 'tenant.charger_operator'
  | 'tenant.driver'
  | 'tenant.none'
export interface ResourceAccess {
  resourceType: 'Tenant' | 'AccessPlan' // currently only type
  display: string
  displayName: string
  resourceId: string
  permissions: PermissionType[]
}

export type TenantPermissionType = 'admin' | 'manager' | 'viewer' | 'support' | 'driver' | 'charger_operator'
export interface TenantPermission extends TenantRef {
  permission: TenantPermissionType
}

export interface AugmentedUser extends User {
  isAdmin: boolean
  isSupport: boolean
  isUser: boolean
  tenantPermission: { [tenantId: string]: AugmentedUserTenantPermission }
  note: string
}

export type DashboardAccessType = 'manager' | 'viewer' | 'none'
export interface AugmentedUserTenantPermission {
  name: string
  id: string
  access?: DashboardAccessType
  driver: boolean
}

export const userInviteInfo = ['none', 'manager', 'viewer', 'charger_operator'] as const
export type UserInviteInfoRoles = typeof userInviteInfo[number]

export interface UserPermissions {
  name: string
  id: string
  access: string
  driver: boolean
  discountPlan?: DiscountSchema[]
  accessPlan?: AccessPlan[]
}

export interface UserOption {
  label: string | number
  value: string | number
  user: UserRef
}

export const toAugmentedUser = (user: User): AugmentedUser => {
  let out: AugmentedUser = { ...user, isAdmin: false, isSupport: false, isUser: false, tenantPermission: {}, note: '' }

  if (user.note) {
    out.note = user.note
  }

  if (user.role === 'admin') {
    out.isAdmin = true
    return out
  }

  if (user.role === 'support') {
    out.isSupport = true
    return out
  }

  if (user.role === 'user') {
    out.isUser = true
  }

  user.accesses.forEach((ra: ResourceAccess) => {
    let p = {
      id: ra.resourceId,
      name: ra.display,
      access: undefined,
      driver: false,
    } as AugmentedUserTenantPermission
    if (ra.permissions.includes('tenant.manager') || ra.permissions.includes('tenant.charger_operator')) {
      p.access = 'manager'
    }
    if (ra.permissions.includes('tenant.viewer')) {
      p.access = 'viewer'
    }
    if (ra.permissions.includes('tenant.driver')) {
      p.driver = true
    }
    out.tenantPermission[ra.resourceId] = p
  })
  return out
}

export const getTenantPermissions = (user: User): TenantPermission[] => {
  let out: TenantPermission[] = []

  if (!user.accesses || user.accesses.length === 0) {
    return out
  }

  user.accesses.forEach((ra: ResourceAccess) => {
    let p = {
      id: ra.resourceId,
      name: ra.display,
      displayName: ra.displayName,
    } as TenantPermission
    if (user.role === 'admin') {
      p.permission = 'admin'
    } else if (user.role === 'support') {
      p.permission = 'support'
    } else if (ra.permissions.includes('tenant.manager') || ra.permissions.includes('tenant.charger_operator')) {
      p.permission = 'manager'
    } else if (ra.permissions.includes('tenant.viewer')) {
      p.permission = 'viewer'
    } else if (ra.permissions.includes('tenant.driver')) {
      return
    } else if (user.role === 'user' && ra.permissions.length === 0) {
      return
    }
    out.push(p)
  })
  return out
}

export const isTenantViewer = (tenant?: TenantPermission): boolean => {
  return checkTenantPermission('viewer', tenant)
}

export const isTenantManager = (tenant?: TenantPermission): boolean => {
  return checkTenantPermission('manager', tenant) || checkTenantPermission('charger_operator', tenant)
}

export const isManagerToTenants = (accesses: ResourceAccess[], charger?: Charger): boolean => {
  return (
    checkMultiTenantPermission('manager', accesses, charger) ||
    checkMultiTenantPermission('charger_operator', accesses, charger)
  )
}

export const isViewerToTenants = (accesses: ResourceAccess[], charger?: Charger): boolean => {
  return checkMultiTenantPermission('viewer', accesses, charger)
}

const checkMultiTenantPermission = (
  perm: TenantPermissionType,
  accesses: ResourceAccess[],
  charger?: Charger,
): boolean => {
  if (!accesses || accesses.length === 0) {
    return false
  }

  return accesses.some((t) => {
    if (!t.permissions) {
      return false
    } else if (charger && t.resourceId === charger.tenant?.id) {
      return checkPermissions(perm, t)
    } else {
      return checkPermissions(perm, t)
    }
  })
}

export const canSelectChargers = (accesses: ResourceAccess[], connector: Connector): boolean => {
  return checkManagerPermissionForChargers(accesses, connector)
}

const checkManagerPermissionForChargers = (accesses: ResourceAccess[], connector: Connector): boolean => {
  if (!accesses) {
    return false
  }

  let viewers: ResourceAccess[] = []
  accesses.forEach((r) => {
    if (r.permissions.includes('tenant.viewer')) {
      return viewers.push(r)
    }
  })

  return accesses.some((t) => {
    if (!t.permissions) {
      return false
    } else {
      return viewers.find((t) => t.resourceId === connector.charger?.tenant?.id)
    }
  })
}

export const canManageCharger = (accesses: ResourceAccess[], charger: Charger): boolean => {
  return checkManageCharger(accesses, charger)
}

const checkManageCharger = (accesses: ResourceAccess[], charger: Charger): boolean => {
  if (!accesses) {
    return false
  }

  return accesses.some((access) => {
    if (!access.permissions) {
      return false
    } else if (charger.tenant?.id === access.resourceId && access.permissions.includes('tenant.viewer')) {
      return true
    } else {
      return false
    }
  })
}

export const checkDriverAccess = (accesses: ResourceAccess[]) => {
  if (!accesses || accesses.length === 0) {
    return false
  }
  return accesses.some((t) => {
    if (!t.permissions) {
      return false
    } else if (
      t.permissions.includes('tenant.manager') ||
      t.permissions.includes('tenant.charger_operator') ||
      t.permissions.includes('tenant.viewer')
    ) {
      return true
    } else {
      return false
    }
  })
}

const checkPermissions = (perm: TenantPermissionType, t: ResourceAccess): boolean => {
  let permiss: string[] = []
  t.permissions.forEach((t) => {
    const role = t.split('.')[1]
    if (role === perm) {
      permiss.push(role)
    }
  })
  return permiss.includes(perm)
}

const checkTenantPermission = (perm: TenantPermissionType, tenant: TenantPermission | undefined): boolean => {
  if (!tenant) {
    return false
  }
  if (!tenant.permission) {
    return false
  }
  return tenant.permission.includes(perm)
}
