export type ErrorData = {
  code: string
  trace_id?: string
  message: string
  details?: string[]
}

export class SwtchError extends Error {
  public description: string
  public cause?: Error
  public messages?: string[]
  public status?: number

  constructor(message: string, messages?: string[], cause?: Error, status?: number) {
    super(message)
    this.description = message
    this.cause = cause
    this.messages = messages
    this.status = status
  }
}

export class HttpError extends SwtchError {
  public response: Response

  constructor(httpResponse: Response, message: string, messages?: string[]) {
    super(message, messages)
    this.response = httpResponse
  }
}

export class SwtchApiError extends SwtchError implements ErrorData {
  public code: string
  public trace_id?: string
  public message: string
  public details?: string[]

  constructor(data: ErrorData, cause?: Error) {
    super(data.message, data.details, cause)

    this.code = data.code
    this.trace_id = data.trace_id
    this.message = data.message
    this.details = data.details
  }

  static async fromHttpError(httpError: HttpError) {
    const body = await httpError.response.text()

    try {
      const jsonBody = JSON.parse(body)
      return new SwtchApiError(jsonBody)
    } catch (error) {
      throw new SwtchError(
        'Could not parse unexpected response from SWTCH API as JSON error',
        [`The returned body should have been a valid JSON object, got '${body}'`],
        error,
      )
    }
  }
}

export class SwtchClientError extends SwtchError {
  constructor(message: string, cause?: Error) {
    super('Error Occurred', [message], cause)
  }
}

export interface AlertBoxprops {
  error?: SwtchError
  closable?: boolean
}
