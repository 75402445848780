import { WebAuth } from 'auth0-js'
import { SwtchApiError, SwtchClientError, SwtchError, HttpError } from '../../models/error'
import { clearSession, getSession } from '../../state/session-store'
import { clearTenant, getTenant } from '../../state/tenant-store'
import { log } from '../../logger'
import {
  buildApiBaseUrl,
  buildApiFleetBaseUrl,
  buildApiManageUrl,
  buildAppUrl,
  buildApiManageUrl2,
  buildMetabaseUrl,
} from '../../helpers/url'
import { Links, Paths } from '../../components/routes/paths'
import configProvider from '../../config'

/**
 * A generic convenience wrapper around fetch() that will automatically:
 *  * log what's happening;
 *  * add 'Content-Type: json' when no headers are given; and
 *  * deserialize responses with the given type
 *
 * @param url
 * @param options
 * @returns
 */
export async function httpClient<T>(url: string, options: RequestInit): Promise<T> {
  const auth0 = new WebAuth({
    clientID: configProvider.config.auth0.clientId,
    domain: configProvider.config.auth0.domain,
    redirectUri: buildAppUrl(Paths.auth0Callback),
    responseType: 'token id_token',
    scope: configProvider.config.auth0.scope,
  })

  if (options.method && options.method !== 'GET')
    options.headers = options.headers || new Headers({ 'Content-Type': 'application/json' })

  try {
    log(
      'Executing request [%s %s](headers: %o, body: %s)',
      options.method || 'get',
      url,
      options.headers,
      options.body || '',
    )

    const response = await fetch(url, options)

    log(
      'Received response [%s %s %s](headers: %o)',
      response.status,
      response.statusText,
      response.url,
      response.headers,
    )

    if (!response.ok) {
      if (response.status === 401) {
        log('should logout')
        clearSession()
        clearTenant()
        auth0.logout({})
        window.location.replace(Links.auth0login())
      } else if (response.status === 400) {
        log('bad request')
        throw await responseBadRequest(response)
      } else if (response.status === 409) {
        log('conflict in request')
        throw await responseConflict(response)
      } else if (response.status === 504) {
        log('Request Timed Out')
        throw await responseBadRequest(response)
      } else {
        throw await responseToSwtchError(response)
      }
    }

    log('Turning response body into response result')
    return await responseToResult<T>(response)
  } catch (error) {
    throw new SwtchClientError('Unable to perform HTTP request correctly', error as Error)
  }
}

async function genericApiClient<T>(baseUrl: string, relativeUrl: string, options: RequestInit): Promise<T> {
  let headerOptions = !options.headers ? { 'Content-Type': 'application/json' } : options.headers

  const session = getSession()
  if (session) {
    log('Setting authorization token for manage API request for [%s].', relativeUrl)

    headerOptions = {
      ...headerOptions,
      Authorization: `Bearer ${session.token}`,
    }
  }

  const tenant = getTenant()
  if (tenant) {
    log('Setting tenant token for manage API request for [%s].', relativeUrl)

    headerOptions = {
      ...headerOptions,
      'X-Tenant-Id': `${tenant.id}`,
    }
  }

  options.headers = new Headers(headerOptions)

  const absoluteUrl = `${baseUrl}${relativeUrl}`

  try {
    return await httpClient<T>(absoluteUrl, options)
  } catch (error) {
    if (error instanceof HttpError) {
      throw await SwtchApiError.fromHttpError(error)
    } else throw error
  }
}

async function genericApiClient2<T>(baseUrl: string, relativeUrl: string, options: RequestInit): Promise<T> {
  let headerOptions = !options.headers ? { 'Content-Type': 'application/json' } : options.headers

  const session = getSession()
  if (session) {
    log('Setting authorization token for manage API request for [%s].', relativeUrl)

    headerOptions = {
      ...headerOptions,
      Authorization: `Bearer ${session.token}`,
    }
  }

  const tenant = getTenant()
  if (tenant) {
    log('Setting tenant token for manage API request for [%s].', relativeUrl)

    headerOptions = {
      ...headerOptions,
      'X-Tenant-Id': `${tenant.id}`,
    }
  }

  options.headers = new Headers(headerOptions)

  const absoluteUrl = `${baseUrl}${relativeUrl}`

  try {
    return await httpClient<T>(absoluteUrl, options)
  } catch (error) {
    if (error instanceof HttpError) {
      throw await SwtchApiError.fromHttpError(error)
    } else throw error
  }
}

export async function apiClient<T>(relativeUrl: string, options: RequestInit): Promise<T> {
  return genericApiClient(buildApiManageUrl(), relativeUrl, options)
}

export async function apiClient2<T>(relativeUrl: string, options: RequestInit): Promise<T> {
  return genericApiClient2(buildApiManageUrl2(), relativeUrl, options)
}

export async function authApiClient<T>(relativeUrl: string, options: RequestInit): Promise<T> {
  return genericApiClient(`${buildApiBaseUrl()}/auth`, relativeUrl, options)
}

export async function apiFleetClient<T>(relativeUrl: string, options: RequestInit): Promise<T> {
  return genericApiClient(buildApiFleetBaseUrl(), relativeUrl, options)
}

export async function apiMetabaseClient<T>(relativeUrl: string, options: RequestInit): Promise<T> {
  return genericApiClient(buildMetabaseUrl(), relativeUrl, options)
}

export async function apiCihClient<T>(relativeUrl: string, options: RequestInit): Promise<T> {
  return genericApiClient(buildMetabaseUrl(), relativeUrl, options)
}

/**
 *
 * Create a SwtchError object representing an unexpected HTTP response, not
 * necessarily from the SWTCH API.
 *
 * @param response
 * @returns
 */
async function responseToSwtchError(response: Response): Promise<SwtchApiError> {
  const result = await response.json().then((data: any) => {
    return new SwtchApiError(data)
  })

  if (result instanceof SwtchApiError) {
    throw new SwtchError(
      `${result.message} ${result.details ? ': ' + result.details : ''}`,
      undefined,
      undefined,
      response.status,
    )
  }

  throw new SwtchError(
    `An unexpected response was received from a back-end service. Status code: ${response.status} (${response.statusText}); URL ${response.url}`,
    undefined,
    undefined,
    response.status,
  )
}

async function responseBadRequest(response: Response): Promise<SwtchError> {
  const result = await response.json()

  if (response.status === 504) {
    throw new SwtchError('Request Timed Out')
  }
  throw new SwtchError(result.details, undefined, undefined, response.status)
}

async function responseConflict(response: Response): Promise<SwtchError> {
  const result = await response.json()
  if (
    response.status === 409 &&
    result.message === 'Diagnostics request already in progress for this charger' &&
    result.command === 'get_diagnostics'
  ) {
    throw new SwtchError(
      'Diagnostics request already in progress for this charger',
      undefined,
      undefined,
      response.status,
    )
  }
  throw new SwtchError(result.details, undefined, undefined, response.status)
}

async function responseToResult<T>(response: Response): Promise<T> {
  try {
    return await response.json()
  } catch (error) {
    throw new SwtchClientError('The returned body should have been a valid JSON object', error as Error)
  }
}
